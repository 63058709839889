import { useState, memo } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';

import theme from '@styles/theme';
import { EmployeeCardDeploy } from '@graphql/types';
import {
    getCardAcceptStatus,
    getEmployeeCardDeployStatus,
} from '@lib/enumChange';
import { autoHyphen } from '@utils/common';
import { useAuth } from '@context/authProvider';

import ReceiveInfoModal from './ReceiveInfoModal';

type EmployeeCardListProps = {
    list?: EmployeeCardDeploy;
    refetch?: () => void;
};

const EmployeeCardList = ({ list, refetch }: EmployeeCardListProps) => {
    const { userLogout } = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const {
        ecdId,
        receivedPath,
        status,
        arrivedDate,
        receivedDate,
        reqUserTeam,
        reqUserName,
        reqUserCellphone,
        receiverTeam,
        receiverName,
        receiverCellphone,
        managerId,
        managerName,
        managerTeamName,
    } = list ?? {};

    if (!list)
        return (
            <TableTitle>
                <CellPath>수령 경로</CellPath>
                <CellStatus>상태</CellStatus>
                <CellArrivalDate>사원증 도착일</CellArrivalDate>
                <CellReceivedDate>사원증 수령일</CellReceivedDate>
                <CellCompany>소속 부서</CellCompany>
                <CellName>이름</CellName>
                <CellPhoneNum>연락처</CellPhoneNum>
                <CellRecipient>수령인</CellRecipient>
                <CellManager>담당자명</CellManager>
            </TableTitle>
        );

    return (
        <>
            <Container
                onClick={() => {
                    if (!receivedPath) {
                        setIsModalOpen(true);
                    } else {
                        return;
                    }
                }}
                $pointer={receivedPath === 0}
            >
                <CellPath>
                    {getCardAcceptStatus(receivedPath as number)}
                </CellPath>
                <CellStatus>
                    {getEmployeeCardDeployStatus(status as number)}
                </CellStatus>
                <CellArrivalDate>
                    {arrivedDate
                        ? dayjs(arrivedDate).format('YYYY-MM-DD HH:mm')
                        : '-'}
                </CellArrivalDate>
                <CellReceivedDate>
                    {receivedDate
                        ? dayjs(receivedDate).format('YYYY-MM-DD HH:mm')
                        : '-'}
                </CellReceivedDate>
                <CellCompany>{reqUserTeam ? reqUserTeam : '-'}</CellCompany>
                <CellName>{reqUserName ? reqUserName : '-'}</CellName>
                <CellPhoneNum>
                    {reqUserCellphone ? autoHyphen(reqUserCellphone) : '-'}
                </CellPhoneNum>
                <CellRecipient>
                    {receiverName ? `${receiverTeam} ${receiverName}` : '-'}
                </CellRecipient>
                <CellManager>{managerName ? managerName : '-'}</CellManager>
            </Container>
            {createPortal(
                isModalOpen && (
                    <ReceiveInfoModal
                        ecdId={ecdId as number}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                    />
                ),
                document.body,
            )}
        </>
    );
};

export default memo(EmployeeCardList);

const Container = styled.li<{ $pointer?: boolean }>`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.componentBorder} 1px solid;
    cursor: ${({ $pointer }) => ($pointer ? 'pointer' : 'auto')};
`;

const TableTitle = styled.li`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.blackColor} 1px solid;
`;

const TableCell = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    height: inherit;
    text-align: center;
    word-break: keep-all;
    line-height: 1.2;
    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.5; */
`;

const CellPath = styled(TableCell)`
    flex: 8%;
`;

const CellStatus = styled(TableCell)`
    flex: 8%;
`;

const CellArrivalDate = styled(TableCell)`
    flex: 12%;
`;

const CellReceivedDate = styled(TableCell)`
    flex: 12%;
`;

const CellCompany = styled(TableCell)`
    flex: 12%;
`;

const CellName = styled(TableCell)`
    flex: 9%;
`;

const CellPhoneNum = styled(TableCell)`
    flex: 11%;
`;

const CellRecipient = styled(TableCell)`
    flex: 15%;
`;

const CellManager = styled(TableCell)`
    flex: 13%;
`;
