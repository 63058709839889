import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import * as E from './EmployeeCard.style';
import { SEE_EMPLOYEE_CARD_DEPLOY_LIST } from '@graphql/queries';
import {
    DOWN_EMPLOYEE_CARD_DEPLOY_FORM,
    CREATE_EMPLOYEE_CARD,
} from '@graphql/mutations';
import {
    Query,
    Mutation,
    ReceptionAdmin,
    EmployeeCardDeploy,
} from '@graphql/types';
import { selectedSite, userVar } from '@store/index';
import usePagination_v2 from '@hooks/usePagination_v2';
import { useAuth } from '@context/authProvider';
import { getEmployeeCardDeployStatus } from '@lib/enumChange';
import { getResetDate } from '@utils/common';

import PaginationV2 from '@components/layout/PaginationV2';
import EmployeeCardList from '@components/feature/EmployeeCard/EmployeeCardList';
import Loader from '@components/share/Loader';
import MuiTableCalendar from '@components/styled/MuiTableCalendar';

const EmployeeCard = () => {
    const navigate = useNavigate();
    const [filter, setFilter] = useState('전체');
    const [searchDate, setSearchDate] = useState<string | Date>(
        dayjs(new Date()).toISOString(),
    );
    // 페이지네이션 관련 상태
    const [take, setTake] = useState(10);
    const [preCursor, setPreCursor] = useState(0);
    const [nextCursor, setNextCursor] = useState(0);
    const [cursorOption, setCursorOption] = useState<boolean | null>(null);

    const { userLogout } = useAuth();
    const workSiteUnique = useReactiveVar(selectedSite);
    const userInfo: ReceptionAdmin = useReactiveVar(userVar);
    const { receptionAdminPermission } = userInfo ?? {};
    const { rap_employeementCard } = receptionAdminPermission ?? {};

    // 접근 권한 없는 사용자 접근 막기
    useEffect(() => {
        if (rap_employeementCard === false) {
            toast.warning('접근 권한이 없는 페이지 입니다.');
            navigate('/visitor');
        }
    }, [rap_employeementCard, navigate]);

    const { data, loading, refetch } = useQuery<
        Pick<Query, 'seeEmployeeCardDeployList'>
    >(SEE_EMPLOYEE_CARD_DEPLOY_LIST, {
        variables: {
            schDate: getResetDate(searchDate),
            // schDate: searchDate,
            status: getEmployeeCardDeployStatus(filter),
            take,
            preCursor,
            nextCursor,
            cursorOption,
        },
        onError: (err) => {
            console.log(err.message);
        },
    });

    const [downEmployeeCardDeployForm] = useMutation<
        Pick<Mutation, 'downEmployeeCardDeployForm'>
    >(DOWN_EMPLOYEE_CARD_DEPLOY_FORM);

    const [createEmployeeCard] =
        useMutation<Pick<Mutation, 'createEmployeeCard'>>(CREATE_EMPLOYEE_CARD);

    const employeeCardDeployList =
        data?.seeEmployeeCardDeployList?.employeeCardDeployList || [];
    const totalLength = data?.seeEmployeeCardDeployList?.totalLength || 0;
    const totalCount = data?.seeEmployeeCardDeployList?.totalCount || 0;
    const undeployed = data?.seeEmployeeCardDeployList?.undeployed || 0;
    const deployed = data?.seeEmployeeCardDeployList?.deployed || 0;

    const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
        resetPaginationHandler();
    };

    const uploadFileHandler = async (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        await createEmployeeCard({
            variables: {
                uploadExcel: e.target.files![0],
            },
            onCompleted: () => {
                toast.info('사원증을 등록했습니다.');
                refetch();
            },
            onError: (err) => {
                console.log(err.message);
            },
        });
    };

    const downloadFileHandler = async () => {
        await downEmployeeCardDeployForm({
            onCompleted: (data) => {
                window.open(data?.downEmployeeCardDeployForm as string);
            },
            onError: (err) => {
                console.log(err.message);
            },
        });
    };

    // 페이지네이션
    const {
        pageIndex,
        setPageIndex,
        pageGroupLength,
        prevPageHandler,
        nextPageHandler,
        prevPageGroupHandler,
        nextPageGroupHandler,
        resetPaginationHandler,
    } = usePagination_v2(
        take,
        totalLength,
        setPreCursor,
        setNextCursor,
        setCursorOption,
        employeeCardDeployList,
        'ecdId',
    );

    return (
        <E.Container>
            <E.Header>
                <E.TitleWrapper>
                    <E.Title>사원증 배포</E.Title>
                    <E.BtnWrapper>
                        <E.TextBtn onClick={downloadFileHandler}>
                            등록 양식 다운로드
                        </E.TextBtn>
                        <E.UploadList htmlFor="parcelListExel">
                            <E.FileInput
                                type="file"
                                id="parcelListExel"
                                name="parcelListExel"
                                accept=".xlsx, .xls"
                                onChange={uploadFileHandler}
                            />
                            사원증 배포 정보 업로드
                        </E.UploadList>
                        {/* <E.TextBtn>사원증 배포 정보 업로드</E.TextBtn> */}
                    </E.BtnWrapper>
                </E.TitleWrapper>
                <E.FilterWrapper>
                    <E.StatusWrapper>
                        <E.Label htmlFor="전체">
                            <E.Radio
                                type="radio"
                                id="전체"
                                name="employeeCardRequest"
                                value="전체"
                                checked={filter === '전체'}
                                onChange={handleFilter}
                            />
                            <E.FilterText>전체 {totalCount}</E.FilterText>
                        </E.Label>
                        <E.Label htmlFor="미배포">
                            <E.Radio
                                type="radio"
                                id="미배포"
                                name="employeeCardRequest"
                                value="미배포"
                                checked={filter === '미배포'}
                                onChange={handleFilter}
                            />
                            <E.FilterText>미배포 {undeployed}</E.FilterText>
                        </E.Label>
                        <E.Label htmlFor="배포완료">
                            <E.Radio
                                type="radio"
                                id="배포완료"
                                name="employeeCardRequest"
                                value="배포완료"
                                checked={filter === '배포완료'}
                                onChange={handleFilter}
                            />
                            <E.FilterText>배포완료 {deployed}</E.FilterText>
                        </E.Label>
                    </E.StatusWrapper>
                    <MuiTableCalendar
                        value={searchDate}
                        onChange={setSearchDate}
                    />
                </E.FilterWrapper>
            </E.Header>
            <E.TableContainer>
                <EmployeeCardList />
                {loading && <Loader />}
                {employeeCardDeployList
                    .slice(pageIndex, pageIndex + take)
                    .map((list, idx: number) => (
                        <EmployeeCardList
                            key={`${idx}`}
                            list={list as EmployeeCardDeploy}
                            refetch={refetch}
                        />
                    ))}
                {!loading && !totalLength && (
                    <E.ListEmpty>조회된 리스트가 없습니다.</E.ListEmpty>
                )}
            </E.TableContainer>
            <PaginationV2
                take={take}
                setTake={setTake}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageGroupLength={pageGroupLength}
                totalLength={totalLength}
                prevPageHandler={prevPageHandler}
                nextPageHandler={nextPageHandler}
                prevPageGroupHandler={prevPageGroupHandler}
                nextPageGroupHandler={nextPageGroupHandler}
                resetPaginationHandler={resetPaginationHandler}
            />
        </E.Container>
    );
};

export default EmployeeCard;
