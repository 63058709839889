import {
    useMutation,
    OperationVariables,
    ApolloQueryResult,
} from '@apollo/client';
import { useState, useEffect, memo } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import { SelectChangeEvent } from '@mui/material';
import dayjs from 'dayjs';

import theme from '@styles/theme';
import { UPDATE_SHIPPING_CANCEL } from '@graphql/mutations';
import { Query, Mutation, Shipping } from '@graphql/types';
import { getShippingStatus } from '@lib/enumChange';
import { autoHyphen, handleTextLength } from '@utils/common';
import { PARCEL_SHIPPING_STATUS_OPTIONS } from '@constants/index';
import MuiSelect from '@components/styled/MuiSelect';
import RequestCancelModal from '@components/share/RequestCancelModal';

type ShippingListProps = {
    list?: Shipping;
    refetch?: (
        variables?: Partial<OperationVariables>,
    ) => Promise<ApolloQueryResult<Pick<Query, 'seeShippingList'>>>;
};
const ShippingList = ({ list, refetch }: ShippingListProps) => {
    const navigate = useNavigate();
    const [status, setStatus] = useState('미발송');
    const [cancelModal, setCancelModal] = useState(false);
    const [reason, setReason] = useState('');
    const {
        rsId,
        receptionStatus,
        createdAt,
        requestor,
        requestorCellphone,
        recipient,
        recipientCellphone,
        address,
        addressDetail,
        itemName,
        trackingNumber,
    } = list ?? {};

    useEffect(() => {
        if (receptionStatus)
            setStatus(getShippingStatus(receptionStatus) as string);
    }, [receptionStatus]);

    const [updateShippingCancel] = useMutation<
        Pick<Mutation, 'updateShippingCancel'>
    >(UPDATE_SHIPPING_CANCEL);

    if (!list)
        return (
            <TableTitle>
                <CellStatus>상태</CellStatus>
                <CellRequestDate>요청일시</CellRequestDate>
                <CellRequestPerson>요청자</CellRequestPerson>
                <CellPhoneNum>연락처</CellPhoneNum>
                <CellReceiver>받는분</CellReceiver>
                <CellReceiverContact>받는분 연락처</CellReceiverContact>
                <CellProduct>상품명</CellProduct>
                <CellAddress>주소</CellAddress>
                <CellParcelNumber>운송장번호</CellParcelNumber>
            </TableTitle>
        );

    const handleMoveToRegistration = () => {
        if (receptionStatus !== 1 && receptionStatus !== 2) return;
        navigate('/parcel-shipment/registration', {
            state: {
                rsId,
            },
        });
    };

    const changeSelectHandler = async (
        e:
            | React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
            | SelectChangeEvent,
    ) => {
        e.stopPropagation();
        const { value } = e.target;
        switch (value) {
            case '미발송':
                break;
            case '취소':
                setStatus(value);
                setCancelModal(true);
                break;
        }
    };

    const trackingParcelHandler = (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        trackingNumber: string,
    ) => {
        if (trackingNumber) {
            e.stopPropagation();
            window.open(
                `https://trace.cjlogistics.com/web/detail.jsp?slipno=${trackingNumber}`,
                '_blank',
                'noopener, noreferer',
            );
        } else {
            return;
        }
    };
    const submitHandler = async (status: string) => {
        await updateShippingCancel({
            variables: {
                rsId,
                requestorType: 2,
                cancelReason: reason,
            },
            onCompleted: () => {
                refetch?.();
                toast.success('배송 신청을 취소했습니다.');
                if (cancelModal) setCancelModal(false);
            },
            onError: (err) => {
                console.log(err.message);
                setReason('');
                setStatus('미발송');
            },
        });
    };
    return (
        <>
            <Container onClick={handleMoveToRegistration}>
                <CellStatus
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                        e.stopPropagation()
                    }
                >
                    {receptionStatus === 1 ? (
                        <MuiSelect
                            name="status"
                            value={status}
                            onChange={(e) => changeSelectHandler(e)}
                            options={PARCEL_SHIPPING_STATUS_OPTIONS}
                            border={false}
                        />
                    ) : (
                        getShippingStatus(receptionStatus as number)
                    )}
                </CellStatus>
                <CellRequestDate>
                    {createdAt
                        ? dayjs(createdAt).format('YYYY-MM-DD HH:MM')
                        : '-'}
                </CellRequestDate>

                <CellRequestPerson>
                    {requestor ? handleTextLength(requestor, 10) : '-'}
                </CellRequestPerson>
                <CellPhoneNum>
                    {requestorCellphone
                        ? autoHyphen(requestorCellphone as string)
                        : '-'}
                </CellPhoneNum>
                <CellReceiver>{recipient ? recipient : '-'}</CellReceiver>
                <CellReceiverContact>
                    {recipientCellphone
                        ? autoHyphen(recipientCellphone as string)
                        : '-'}
                </CellReceiverContact>
                <CellProduct>
                    {itemName ? handleTextLength(itemName, 8) : '-'}
                </CellProduct>
                <CellAddress>
                    {address
                        ? handleTextLength(`${address} ${addressDetail}`, 26)
                        : '-'}
                </CellAddress>
                <CellParcelNumber>
                    {trackingNumber ? (
                        <TextBlue
                            onClick={(e) =>
                                trackingParcelHandler(
                                    e,
                                    trackingNumber as string,
                                )
                            }
                        >
                            {trackingNumber}
                        </TextBlue>
                    ) : (
                        '-'
                    )}
                </CellParcelNumber>
            </Container>
            {createPortal(
                cancelModal && (
                    <RequestCancelModal
                        status={status}
                        reason={reason}
                        setStatus={setStatus}
                        setCancelModal={setCancelModal}
                        setReason={setReason}
                        submitHandler={submitHandler}
                        customStatus="미발송"
                    />
                ),
                document.body,
            )}
        </>
    );
};

export default memo(ShippingList);

const Container = styled.li<{ $disabled?: boolean }>`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.componentBorder} 1px solid;
    cursor: pointer;
    ${({ $disabled }) =>
        $disabled &&
        css`
            opacity: 0.3;
            cursor: auto;
        `};

    ${({ $disabled }) =>
        !$disabled &&
        css`
            &:hover {
                background-color: ${theme.colors.paginationBorder};
            }
        `};
`;

const TableTitle = styled.li`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.blackColor} 1px solid;
`;

const TableCell = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    height: inherit;
    text-align: center;
    word-break: keep-all;
    line-height: 1.2;
    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.5; */
`;

const CellStatus = styled(TableCell)`
    flex: 7%;
`;

const CellRequestDate = styled(TableCell)`
    flex: 11%;
`;

const CellRequestPerson = styled(TableCell)`
    flex: 10%;
`;

const CellPhoneNum = styled(TableCell)`
    flex: 10%;
`;

const CellReceiver = styled(TableCell)`
    flex: 6%;
`;

const CellReceiverContact = styled(TableCell)`
    flex: 10%;
`;

const CellProduct = styled(TableCell)`
    flex: 9%;
`;

const CellAddress = styled(TableCell)`
    flex: 27%;
`;

const CellParcelNumber = styled(TableCell)`
    flex: 10%;
`;

const TextBlue = styled.span`
    color: ${theme.colors.positive};
`;
