import { useQuery, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { IoArrowBackSharp } from 'react-icons/io5';
import styled from 'styled-components';
import dayjs from 'dayjs';

import theme from '@styles/theme';
import { SEE_RECEPTION_COUNT } from '@graphql/queries';
import { Query } from '@graphql/types';
import { useAuth } from '@context/authProvider';
import {
    selectedDate,
    selectedSite,
    newVisitor,
    newMuPeople,
    newParking,
} from '@store';

const SideNavigation = () => {
    const location = useLocation();
    const { pathname } = location;
    const { userLogout } = useAuth();
    const searchDate = useReactiveVar(selectedDate);
    const siteUnique = useReactiveVar(selectedSite);
    const visitor = useReactiveVar(newVisitor);
    const muPeople = useReactiveVar(newMuPeople);
    const parking = useReactiveVar(newParking);

    const { data, refetch } = useQuery<Pick<Query, 'seeReceptionCount'>>(
        SEE_RECEPTION_COUNT,
        {
            variables: {
                searchDate,
                workSiteUnique: siteUnique,
            },
            skip: !siteUnique,
            onCompleted: (data) => {
                if (!data.seeReceptionCount?.tokenExpired as boolean)
                    userLogout();
            },
            onError: (err) => console.log(err.message),
        },
    );

    const { visitorsCount, muPeopleCount, parkingCount } =
        data?.seeReceptionCount || {};

    useEffect(() => {
        (async () => {
            if (visitor || muPeople || parking) {
                await refetch();
            }
        })();
    }, [visitor, muPeople, parking, refetch]);

    return (
        <>
            {pathname.includes('registration') ||
            pathname.includes('detail') ? (
                <Container>
                    <GoBack onClick={() => window.history.go(-1)}>
                        <IoArrowBackSharp
                            size={36}
                            color={theme.colors.blackColor}
                        />
                    </GoBack>
                </Container>
            ) : (
                <Container>
                    <Wrapper>
                        <Navigation
                            to="/visitor"
                            className={({ isActive }) =>
                                isActive ? 'active' : ''
                            }
                        >
                            {`방문자 (${visitorsCount ?? 0})`}
                        </Navigation>
                        <Navigation
                            to="/people"
                            className={({ isActive }) =>
                                isActive ? 'active' : ''
                            }
                        >
                            {`무신사 피플 (${muPeopleCount ?? 0})`}
                        </Navigation>
                        <Navigation
                            to="/parking"
                            className={({ isActive }) =>
                                isActive ? 'active' : ''
                            }
                        >
                            {`주차 (${parkingCount ?? 0})`}
                        </Navigation>
                        <Navigation
                            to="/employee-card"
                            className={({ isActive }) =>
                                isActive ? 'active' : ''
                            }
                        >
                            {`사원증 배포`}
                        </Navigation>
                        <Navigation
                            to="/parcel-shipment"
                            className={({ isActive }) =>
                                isActive ? 'active' : ''
                            }
                        >
                            배송신청서
                        </Navigation>
                        <Navigation
                            to="/parcel-recipiency"
                            className={({ isActive }) =>
                                isActive ? 'active' : ''
                            }
                        >
                            택배 및 등기 수령
                        </Navigation>
                        <Navigation
                            to="/travelkit"
                            className={({ isActive }) =>
                                isActive ? 'active' : ''
                            }
                        >
                            TRAVEL KIT
                        </Navigation>
                    </Wrapper>
                </Container>
            )}
        </>
    );
};

export default SideNavigation;

const Container = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 40px;
    width: 200px;
    height: auto;
    /* height: calc(100vh - 415px); */
`;

const Wrapper = styled.div`
    margin-top: 228px;
`;
const Navigation = styled(NavLink)`
    display: flex;
    align-items: center;
    width: 200px;
    /* width: 100%; */
    height: 50px;
    font-size: 18px;
    color: ${theme.colors.disabled};
    font-family: AppleSDGothicNeoBold, sans-serif;
    &.active {
        color: ${theme.colors.blackColor};
    }
`;

const GoBack = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    width: 36px;
    height: 36px;
    color: ${theme.colors.disabled};
`;
