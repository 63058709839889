import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styled from 'styled-components';

import theme from '@styles/theme';

type KeyValueStringType = {
    [key: string]: string;
};

type MuiSelectTypes = {
    title?: string;
    name: string;
    value: string;
    onChange: (
        e:
            | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
            | SelectChangeEvent,
    ) => void;
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    options: KeyValueStringType[];
    placeholder?: string;
    width?: string;
    disabled?: boolean;
    optionsDisabledList?: any[];
    border?: boolean;
    margin?: string;
};

const MuiSelect = ({
    title,
    name,
    value,
    onChange,
    onClick,
    options,
    placeholder,
    width,
    disabled,
    optionsDisabledList,
    border = true,
    margin,
}: MuiSelectTypes) => {
    return (
        <Wrapper $margin={margin}>
            <SelectWrapper
                $width={width}
                $placeholder={value}
                disabled={disabled}
                $border={border}
            >
                {title && <Title>{title}</Title>}
                <Select
                    name={name}
                    id={name}
                    value={value}
                    onChange={onChange}
                    onClick={onClick}
                    MenuProps={{ disablePortal: true }}
                    displayEmpty
                >
                    {placeholder && (
                        <MenuItem value="" disabled>
                            {placeholder}
                        </MenuItem>
                    )}
                    {options.map((option: KeyValueStringType, idx: number) => (
                        <MenuItem
                            key={`${idx}-${option.value}`}
                            value={option.value}
                            disabled={optionsDisabledList?.includes(
                                option.value,
                            )}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </SelectWrapper>
        </Wrapper>
    );
};

export default MuiSelect;

const Title = styled.div`
    margin-bottom: 8px;
`;

const Wrapper = styled.div<{ $margin?: string }>`
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
`;

const SelectWrapper = styled(FormControl)<{
    $width?: string;
    $placeholder: string;
    $border?: boolean;
}>`
    width: ${({ $width }) => ($width ? `${$width}` : 'auto')};
    font-family: AppleSDGothicNeoMedium, sans-serif;
    .MuiSelect-select {
        padding: 14px 12px;
        font-size: 15px;
        color: ${({ $placeholder }) =>
            $placeholder.length !== 0
                ? theme.colors.blackColor
                : theme.colors.disabled} !important;
        border: ${({ $border }) =>
            $border
                ? `${theme.colors.componentBorder} 1px solid !important`
                : 'none'};
        &:hover,
        &:focus {
            border: ${({ $border }) =>
                    $border ? `${theme.colors.blackColor}` : 'none'}
                1px solid !important;
        }
        &.Mui-disabled {
            color: ${theme.colors.disabled} !important;
            cursor: auto !important;
            -webkit-text-fill-color: unset !important;
        }
    }
    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
    .MuiSvgIcon-root {
        color: ${theme.colors.darkIconColor}!important;
    }
`;
