import dayjs from 'dayjs';

export const getResetDate = (date: string | Date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear(); // 년
    const month = newDate.getMonth(); // 월
    const day = newDate.getDate(); // 일

    // 어제 날짜 구하기
    const prevDate = new Date(year, month, day - 1).toLocaleDateString();

    return `${new Date(prevDate).getFullYear()}-${(
        new Date(prevDate).getMonth() + 1
    )
        .toString()
        .padStart(2, '0')}-${new Date(prevDate)
        .getDate()
        .toString()
        .padStart(2, '0')}T15:00:00.000Z`;
};

export const dateFormat = (dateString: string, type: string) => {
    let year;
    let month;
    let day;
    let week;
    let hour;
    let min;
    let date = new Date(parseInt(dateString, 10));

    switch (type) {
        case 'yyyy.MM.dd':
            year = date.getFullYear().toString();
            month = date.getMonth() + 1;
            day = date.getDate();

            month = month >= 10 ? month : '0' + month;
            day = day >= 10 ? day : '0' + day;

            return year + '. ' + month + '. ' + day + ` (${week})`;
        case 'yyyy-MM-dd':
            year = date.getFullYear().toString();
            month = date.getMonth() + 1;
            day = date.getDate();

            month = month >= 10 ? month : '0' + month;
            day = day >= 10 ? day : '0' + day;

            return year + '-' + month + '-' + day;
        case 'yyyy-MM-dd hh:mm':
            year = date.getFullYear().toString();
            month = date.getMonth() + 1;
            day = date.getDate();
            hour = date.getHours();
            min = date.getMinutes();

            month = month >= 10 ? month : '0' + month;
            day = day >= 10 ? day : '0' + day;

            hour = hour >= 10 ? hour : '0' + hour;
            min = min >= 10 ? min : '0' + min;

            return `${year}-${month}-${day} ${hour}:${min}`;
        default:
            return date;
    }
};

// 휴대전화 Hyphen 넣기
export const autoHyphen = (phoneNumber: string) => {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        7,
    )}-${phoneNumber.slice(7, 11)}`;
};

/**
 * todo 디데이 구하기
 * @param {string} date 비교일
 */
export const getDDay = (date: string) => {
    const today = dayjs(new Date()).format('YYYY-MM-DD');

    return `+${Math.abs(dayjs(today).diff(date, 'day'))}일`;
};

/**
 * todo 말줄임 처리
 * @param {string} text
 * @param {number} length
 */
export const handleTextLength = (text: string, length: number) => {
    return `${text.length > length ? `${text.slice(0, length - 1)}...` : text}`;
};
