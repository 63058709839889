import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { SelectChangeEvent } from '@mui/material';

import * as T from './TravelKitDetail.style';
import theme from '@styles/theme';
import { SEE_WORKSITE_TK_DETAIL } from '@graphql/queries';
import { UPDATE_TK_RECEIVE_STATUS } from '@graphql/mutations';
import { Query, Mutation } from '@graphql/types';
import { TRAVEL_KIT_RENTAL_STATUS } from '@constants/index';
import { getTKRentalStatus, getTKType } from '@lib/enumChange';

import Loader from '@components/share/Loader';
import MuiSelect from '@components/styled/MuiSelect';

const TravelKitDetail = () => {
    const { state } = useLocation();
    const { tksId } = state;
    const [status, setStatus] = useState('보유');

    const { data, loading, refetch } = useQuery<
        Pick<Query, 'seeWorksiteTKDetail'>
    >(SEE_WORKSITE_TK_DETAIL, {
        variables: {
            tksId,
        },
        onError: (err) => {
            console.log(err.message);
        },
    });

    const [updateTKReceiveStatus] = useMutation<
        Pick<Mutation, 'updateTKReceiveStatus'>
    >(UPDATE_TK_RECEIVE_STATUS);

    const {
        tkType,
        tkName,
        component,
        size,
        rentalStartDate,
        rentalEndDate,
        userTeam,
        userName,
        reqText,
        latestRentedStartDate,
        latestRentedEndDate,
        latestRentedUserTeam,
        latestRentedUserName,
        latestRentedText,
        rentalStatus,
    } = data?.seeWorksiteTKDetail || {};

    useEffect(() => {
        setStatus(getTKRentalStatus(rentalStatus as number) as string);
    }, [rentalStatus]);

    const changeSelectHandler = async (
        e:
            | React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
            | SelectChangeEvent,
    ) => {
        const { value } = e.target;
        setStatus(value);
        submitHandler(value);
    };

    const submitHandler = async (status: string) => {
        if (!window.confirm('상태를 변경하시겠습니까?')) {
            setStatus(getTKRentalStatus(rentalStatus as number) as string);
            return;
        }

        await updateTKReceiveStatus({
            variables: {
                tksId,
                rentalStatus: getTKRentalStatus(status) as number,
            },
            onCompleted: () => {
                refetch();
                toast.success('Travel Kit 대여 상태를 변경했습니다.');
            },
            onError: (err) => {
                console.log(err.message);
                setStatus(getTKRentalStatus(rentalStatus as number) as string);
            },
        });
    };

    return loading ? (
        <Loader />
    ) : (
        <T.Container>
            <T.InfoWrapper>
                <T.Title>TRAVEL KIT - 상세정보</T.Title>
                <T.InfoArea>
                    <T.InfoType>대여정보</T.InfoType>
                    <T.InfoListArea>
                        <T.InfoList>
                            <T.InfoTitle>종류</T.InfoTitle>
                            <T.InfoText>
                                {tkType ? getTKType(tkType as number) : '-'}
                            </T.InfoText>
                        </T.InfoList>
                        <T.InfoList>
                            <T.InfoTitle>구성품명</T.InfoTitle>
                            <T.InfoText>{tkName ? tkName : '-'}</T.InfoText>
                        </T.InfoList>
                        <T.InfoList>
                            <T.InfoTitle>구성 설명</T.InfoTitle>
                            <T.InfoText>
                                {component ? component : '-'}
                            </T.InfoText>
                        </T.InfoList>
                        <T.InfoList>
                            <T.InfoTitle>사이즈</T.InfoTitle>
                            <T.InfoText>{size ? size : '-'}</T.InfoText>
                        </T.InfoList>
                        <T.InfoList>
                            <T.InfoTitle>대여기한</T.InfoTitle>
                            <T.InfoText>
                                {rentalStartDate
                                    ? `${dayjs(rentalStartDate).format(
                                          'YYYY-MM-DD',
                                      )} ~ ${dayjs(rentalEndDate).format(
                                          'YYYY-MM-DD',
                                      )}`
                                    : '-'}
                            </T.InfoText>
                        </T.InfoList>
                        <T.InfoList>
                            <T.InfoTitle>요청자</T.InfoTitle>
                            <T.InfoText>
                                {userName ? `${userTeam} - ${userName}` : '-'}
                            </T.InfoText>
                        </T.InfoList>
                        <T.InfoList>
                            <T.InfoTitle>대여사유</T.InfoTitle>
                            <T.InfoText>{reqText ? reqText : '-'}</T.InfoText>
                        </T.InfoList>
                    </T.InfoListArea>
                </T.InfoArea>
                <T.InfoArea>
                    <T.InfoType>직전 대여자 정보 보기</T.InfoType>
                    <T.InfoListArea>
                        <T.InfoList>
                            <T.InfoTitle>대여기한</T.InfoTitle>
                            <T.InfoText
                                $fontColor={
                                    rentalStatus === 5
                                        ? theme.colors.caution
                                        : theme.colors.blackColor
                                }
                            >
                                {latestRentedStartDate
                                    ? `${dayjs(latestRentedStartDate).format(
                                          'YYYY-MM-DD',
                                      )} ~ ${dayjs(latestRentedEndDate).format(
                                          'YYYY-MM-DD',
                                      )}`
                                    : '-'}
                            </T.InfoText>
                        </T.InfoList>
                        <T.InfoList>
                            <T.InfoTitle>요청자</T.InfoTitle>
                            <T.InfoText>
                                {latestRentedUserName
                                    ? `${latestRentedUserTeam} - ${latestRentedUserName}`
                                    : '-'}
                            </T.InfoText>
                        </T.InfoList>
                        <T.InfoList>
                            <T.InfoTitle>대여사유</T.InfoTitle>
                            <T.InfoText>
                                {latestRentedText ? latestRentedText : '-'}
                            </T.InfoText>
                        </T.InfoList>
                    </T.InfoListArea>
                </T.InfoArea>
                <T.InputArea>
                    <T.InfoType>처리정보</T.InfoType>
                    <T.InfoListArea>
                        <T.InfoInput>
                            <T.InfoTitleBold>처리 상태</T.InfoTitleBold>
                            <MuiSelect
                                name="status"
                                value={
                                    status
                                        ? status
                                        : (getTKRentalStatus(
                                              rentalStatus as number,
                                          ) as string)
                                }
                                onChange={(e) => changeSelectHandler(e)}
                                onClick={(e) => e.stopPropagation()}
                                options={TRAVEL_KIT_RENTAL_STATUS}
                                border={true}
                                optionsDisabledList={[
                                    '승인대기',
                                    '예약',
                                    '미반납',
                                ]}
                                width="440px"
                            />
                        </T.InfoInput>
                        <T.Description>
                            관리자에서 승인대기전입니다. 승인 대기 후 처리
                            가능합니다.
                        </T.Description>
                    </T.InfoListArea>
                </T.InputArea>
            </T.InfoWrapper>
        </T.Container>
    );
};

export default TravelKitDetail;
