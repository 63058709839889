import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import { IoAddSharp } from 'react-icons/io5';
import { LiaDownloadSolid } from 'react-icons/lia';
import { toast } from 'react-toastify';

import * as T from './TravelKit.style';
import { SEE_WORKSITE_TK_LIST } from '@graphql/queries';
import { Query, ReceptionAdmin, RentedTravelKit } from '@graphql/types';
import { userVar, selectedSite } from '@store/index';
import usePagination_v2 from '@hooks/usePagination_v2';
import { getTKRentalStatus } from '@lib/enumChange';

import PaginationV2 from '@components/layout/PaginationV2';
import TravelKitList from '@components/feature/TravelKit/TravelKitList';
import Loader from '@components/share/Loader';

const TravelKit = () => {
    const navigate = useNavigate();
    const workSiteUnique = useReactiveVar(selectedSite);
    const [status, setStatus] = useState('전체');
    // 페이지네이션 관련 상태
    const [take, setTake] = useState(10);
    const [preCursor, setPreCursor] = useState(0);
    const [nextCursor, setNextCursor] = useState(0);
    const [cursorOption, setCursorOption] = useState<boolean | null>(null);

    const userInfo: ReceptionAdmin = useReactiveVar(userVar);
    const { receptionAdminPermission } = userInfo ?? {};
    const { rap_travelKit } = receptionAdminPermission ?? {};

    // 접근 권한 없는 사용자 접근 막기
    useEffect(() => {
        if (rap_travelKit === false) {
            toast.warning('접근 권한이 없는 페이지 입니다.');
            navigate('/visitor');
        }
    }, [rap_travelKit, navigate]);

    const { data, loading, refetch } = useQuery<
        Pick<Query, 'seeWorksiteTKList'>
    >(SEE_WORKSITE_TK_LIST, {
        variables: {
            workSiteUnique,
            status: getTKRentalStatus(status as string),
            take,
            preCursor,
            nextCursor,
            cursorOption,
        },
        onError: (err) => {
            console.log(err.message);
        },
    });
    // 임시 데이터
    const travelKitList = data?.seeWorksiteTKList?.travelKitList || [];
    const totalLength = data?.seeWorksiteTKList?.totalLength || 0;
    const totalCount = data?.seeWorksiteTKList?.totalCount || 0;
    const availableCount = data?.seeWorksiteTKList?.availableCount || 0;
    const waitingApprovalCount =
        data?.seeWorksiteTKList?.waitingApprovalCount || 0;
    const reservedCount = data?.seeWorksiteTKList?.reservedCount || 0;
    const rentedCount = data?.seeWorksiteTKList?.rentedCount || 0;
    const notReturnedCount = data?.seeWorksiteTKList?.notReturnedCount || 0;
    const inspectionCount = data?.seeWorksiteTKList?.inspectionCount || 0;
    const maintenanceCount = data?.seeWorksiteTKList?.maintenanceCount || 0;

    const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStatus(e.target.value);
        resetPaginationHandler();
    };

    const uploadFileHandler = () => {
        // TODO: 추후 뮤테이션 추가
        console.log('upload');
    };

    const downloadFileHandler = () => {
        // TODO: 추후 뮤테이션 추가
        console.log('download');
    };

    // 페이지네이션
    const {
        pageIndex,
        setPageIndex,
        pageGroupLength,
        prevPageHandler,
        nextPageHandler,
        prevPageGroupHandler,
        nextPageGroupHandler,
        resetPaginationHandler,
    } = usePagination_v2(
        take,
        totalLength,
        setPreCursor,
        setNextCursor,
        setCursorOption,
        travelKitList,
        'tksId',
    );

    return (
        <T.Container>
            <T.Header>
                <T.TitleWrapper>
                    <T.Title>TRAVEL KIT 리스트</T.Title>
                    {/* <T.BtnWrapper>
                        <T.LinkAddList>
                            <T.FileInput
                                type="file"
                                id="travelKitListExel"
                                name="travelKitListExel"
                                accept=".xlsx, .xls"
                                onChange={uploadFileHandler}
                            />
                            <IoAddSharp size={24} />
                        </T.LinkAddList>
                        <T.BtnDownload onClick={downloadFileHandler}>
                            <LiaDownloadSolid size={20} />
                        </T.BtnDownload>
                    </T.BtnWrapper> */}
                </T.TitleWrapper>
                <T.FilterWrapper>
                    <T.StatusWrapper>
                        <T.Label htmlFor="전체">
                            <T.Radio
                                type="radio"
                                id="전체"
                                name="travelKit"
                                value="전체"
                                checked={status === '전체'}
                                onChange={handleFilter}
                            />
                            <T.FilterText>전체 {totalCount}</T.FilterText>
                        </T.Label>
                        <T.Label htmlFor="보유">
                            <T.Radio
                                type="radio"
                                id="보유"
                                name="travelKit"
                                value="보유"
                                checked={status === '보유'}
                                onChange={handleFilter}
                            />
                            <T.FilterText>보유 {availableCount}</T.FilterText>
                        </T.Label>
                        <T.Label htmlFor="승인대기">
                            <T.Radio
                                type="radio"
                                id="승인대기"
                                name="travelKit"
                                value="승인대기"
                                checked={status === '승인대기'}
                                onChange={handleFilter}
                            />
                            <T.FilterText>
                                승인대기 {waitingApprovalCount}
                            </T.FilterText>
                        </T.Label>
                        <T.Label htmlFor="예약">
                            <T.Radio
                                type="radio"
                                id="예약"
                                name="travelKit"
                                value="예약"
                                checked={status === '예약'}
                                onChange={handleFilter}
                            />
                            <T.FilterText>예약 {reservedCount}</T.FilterText>
                        </T.Label>
                        <T.Label htmlFor="대여">
                            <T.Radio
                                type="radio"
                                id="대여"
                                name="travelKit"
                                value="대여"
                                checked={status === '대여'}
                                onChange={handleFilter}
                            />
                            <T.FilterText>대여 {rentedCount}</T.FilterText>
                        </T.Label>
                        <T.Label htmlFor="미반납">
                            <T.Radio
                                type="radio"
                                id="미반납"
                                name="travelKit"
                                value="미반납"
                                checked={status === '미반납'}
                                onChange={handleFilter}
                            />
                            <T.FilterText>
                                미반납 {notReturnedCount}
                            </T.FilterText>
                        </T.Label>
                        <T.Label htmlFor="검수">
                            <T.Radio
                                type="radio"
                                id="검수"
                                name="travelKit"
                                value="검수"
                                checked={status === '검수'}
                                onChange={handleFilter}
                            />
                            <T.FilterText>검수 {inspectionCount}</T.FilterText>
                        </T.Label>
                        <T.Label htmlFor="점검">
                            <T.Radio
                                type="radio"
                                id="점검"
                                name="travelKit"
                                value="점검"
                                checked={status === '점검'}
                                onChange={handleFilter}
                            />
                            <T.FilterText>점검 {maintenanceCount}</T.FilterText>
                        </T.Label>
                    </T.StatusWrapper>
                </T.FilterWrapper>
            </T.Header>
            <T.TableContainer>
                <TravelKitList />
                {loading && <Loader />}
                {(travelKitList as RentedTravelKit[])
                    .slice(pageIndex, pageIndex + take)
                    .map((list: any, idx: number) => (
                        <TravelKitList
                            key={`${idx}-${list?.pp_id}`}
                            list={list}
                            refetch={refetch}
                        />
                    ))}
                {!loading && !totalLength && (
                    <T.ListEmpty>조회된 리스트가 없습니다.</T.ListEmpty>
                )}
            </T.TableContainer>
            <PaginationV2
                take={take}
                setTake={setTake}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageGroupLength={pageGroupLength}
                totalLength={totalLength}
                prevPageHandler={prevPageHandler}
                nextPageHandler={nextPageHandler}
                prevPageGroupHandler={prevPageGroupHandler}
                nextPageGroupHandler={nextPageGroupHandler}
                resetPaginationHandler={resetPaginationHandler}
            />
        </T.Container>
    );
};

export default TravelKit;
