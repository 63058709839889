import {
    useContext,
    createContext,
    useState,
    useMemo,
    ReactNode,
    useCallback,
} from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { userVar } from '@store/index';
import { PRIV_AUTH_CANCEL_RECEPTION_ADMIN } from '@graphql/mutations';
import { Mutation } from '@graphql/types';
import { toast } from 'react-toastify';

type AuthContextProps = {
    userLogin: (token: string) => void;
    userLogout: () => void;
};

type AuthProviderProps = {
    children: ReactNode;
};

const AuthContext = createContext<AuthContextProps>({
    userLogin: () => {},
    userLogout: () => {},
});

const AuthProvider = ({ children }: AuthProviderProps) => {
    const userInfo = useReactiveVar(userVar);
    const [privAuthCancelReceptionAdmin] = useMutation<
        Pick<Mutation, 'privAuthCancelReceptionAdmin'>
    >(PRIV_AUTH_CANCEL_RECEPTION_ADMIN);

    const userLogin = useCallback(async (token: string) => {
        sessionStorage.setItem('musinsa_reception_token', token);
        window.location.href = '/visitor';
        return;
    }, []);

    const userLogout = useCallback(async () => {
        await privAuthCancelReceptionAdmin({
            variables: {
                raId: userInfo.ra_id,
            },
            onCompleted: () => {
                sessionStorage.removeItem('site');
                sessionStorage.removeItem('userId');
                sessionStorage.removeItem('musinsa_reception_token');
                window.location.href = '/';
            },
            onError: (err) => console.log(err),
        });
    }, [privAuthCancelReceptionAdmin, userInfo.ra_id]);

    const AuthInfo = useMemo(() => {
        return {
            userLogin,
            userLogout,
        };
    }, [userLogin, userLogout]);

    return (
        <AuthContext.Provider value={AuthInfo}>{children}</AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};
