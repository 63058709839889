import styled from 'styled-components';
import theme from '@styles/theme';

export const Container = styled.main`
    width: 1360px;
`;

export const Header = styled.div`
    margin-top: 100px;
    font-family: AppleSDGothicNeoBold, sans-serif;
`;
export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
`;

export const Title = styled.h2`
    font-size: 32px;
`;

export const SendAlert = styled.button`
    font-size: 18px;
    color: ${theme.colors.disabled};
`;

export const FilterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
`;

export const StatusWrapper = styled.div``;

export const Label = styled.label`
    input:checked + span {
        color: ${theme.colors.blackColor};
    }
`;

export const Radio = styled.input`
    display: none;
`;

export const FilterText = styled.span`
    display: inline-block;
    margin-right: 20px;
    font-size: 18px;
    color: ${theme.colors.disabled};
    cursor: pointer;
`;

export const TableContainer = styled.ul`
    width: 100%;
    border-top: ${theme.colors.blackColor} 4px solid;
`;

export const ListEmpty = styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    border-bottom: ${theme.colors.componentBorder} 1px solid;
`;

export const BtnWrapper = styled.div``;

export const TextBtn = styled.button`
    font-size: 18px;
    color: ${theme.colors.disabled};
    margin-left: 40px;
    cursor: pointer;
`;

export const UploadList = styled.label`
    font-family: AppleSDGothicNeoRegular, sans-serif;
    font-size: 18px;
    color: ${theme.colors.disabled};
    margin-left: 40px;
    cursor: pointer;
`;

export const FileInput = styled.input`
    display: none;
`;
