import { useState, useCallback } from 'react';

const usePagination_v2 = (
    take: number,
    totalLength: number,
    setPreCursor: React.Dispatch<React.SetStateAction<number>>,
    setNextCursor: React.Dispatch<React.SetStateAction<number>>,
    setCursorOption: React.Dispatch<React.SetStateAction<boolean | null>>,
    lists: any[],
    id: string,
    modalCloseHandler?: () => void,
) => {
    // 페이지 그룹 변화 시 게시물 수. 0, take * 10, take * 20 씩으로 증감시켜 사용
    const [pageGroupLength, setPageGroupLength] = useState(0);
    // 데이터 수를 10으로 나누 것. ex) 0, 10, 20..
    const [pageIndex, setPageIndex] = useState(0);

    const prevPageHandler = useCallback(() => {
        if (pageGroupLength === 0 && pageIndex === 0) {
            alert('마지막 페이지입니다.');
            return;
        }
        if (pageIndex === 0) {
            //이전 페이지 그룹으로 넘어가야되는 경우
            setPreCursor(lists[0][`${id}`] || 0);
            setCursorOption(false);
            setPageIndex(take * 9);
            setPageGroupLength((prev: number) => prev - take * 10);
        } else {
            //이전 페이지
            setPageIndex((prev: number) => prev - take);
        }
        modalCloseHandler?.();
    }, [
        modalCloseHandler,
        pageGroupLength,
        take,
        lists,
        pageIndex,
        setPageIndex,
        setPageGroupLength,
        setPreCursor,
        setCursorOption,
        id,
    ]);

    const nextPageHandler = useCallback(() => {
        if (totalLength <= pageGroupLength + pageIndex + take) {
            alert('마지막 페이지입니다.');
            return;
        }
        if (pageIndex === take * 9) {
            // 다음 페이지 그룹으로 넘어가야되는 경우
            setNextCursor(lists[lists.length - 1][`${id}`] || 0);
            setCursorOption(true);
            setPageGroupLength((prev: number) => prev + take * 10);
            setPageIndex(0);
        } else {
            // 다음 페이지
            setPageIndex((prev: number) => prev + take);
        }
        modalCloseHandler?.();
    }, [
        modalCloseHandler,
        pageGroupLength,
        take,
        totalLength,
        lists,
        pageIndex,
        setPageIndex,
        setPageGroupLength,
        setNextCursor,
        setCursorOption,
        id,
    ]);

    const prevPageGroupHandler = useCallback(() => {
        if (pageGroupLength === 0) {
            alert('마지막 페이지 그룹입니다.');
        } else {
            setPreCursor(lists[0][`${id}`] || 0);
            setCursorOption(false);
            // setPageIndex(take * 9);
            setPageIndex(0);
            setPageGroupLength((prev: number) => prev - take * 10);
        }
        modalCloseHandler?.();
    }, [
        modalCloseHandler,
        pageGroupLength,
        take,
        lists,
        setPageIndex,
        setPageGroupLength,
        setPreCursor,
        setCursorOption,
        id,
    ]);

    const nextPageGroupHandler = useCallback(() => {
        if (totalLength - take * 10 <= pageGroupLength) {
            alert('마지막 페이지 그룹입니다.');
        } else {
            setNextCursor(lists[lists.length - 1][`${id}`] || 0);
            setCursorOption(true);
            setPageIndex(0);
            setPageGroupLength((prev: number) => prev + take * 10);
        }
        modalCloseHandler?.();
    }, [
        modalCloseHandler,
        pageGroupLength,
        take,
        totalLength,
        lists,
        setPageIndex,
        setPageGroupLength,
        setNextCursor,
        setCursorOption,
        id,
    ]);

    const resetPaginationHandler = useCallback(() => {
        setPreCursor(0); // 페이지네이션 초기화
        setNextCursor(0);
        setCursorOption(null);
        setPageIndex(0);
        setPageGroupLength(0);
    }, [
        setPageIndex,
        setPageGroupLength,
        setPreCursor,
        setNextCursor,
        setCursorOption,
    ]);

    return {
        pageIndex,
        setPageIndex,
        pageGroupLength,
        prevPageHandler,
        nextPageHandler,
        prevPageGroupHandler,
        nextPageGroupHandler,
        resetPaginationHandler,
    };
};

export default usePagination_v2;
