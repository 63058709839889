import { gql } from '@apollo/client';
// 리셉션 > 로그인
export const RECEPTION_LOGIN = gql`
    mutation ReceptionLogin($accountId: String!, $password: String!) {
        receptionLogin(accountId: $accountId, password: $password) {
            token
        }
    }
`;
// 리셉션 > 2차 인증
export const AUTHENTICATE_RECEPTION_ADMIN = gql`
    mutation AuthenticateReceptionAdmin(
        $accountId: String!
        $password: String!
    ) {
        authenticateReceptionAdmin(accountId: $accountId, password: $password)
    }
`;
// 리셉션 > 2차 인증 만료 확인
export const PRIV_AUTH_CANCEL_RECEPTION_ADMIN = gql`
    mutation PrivAuthCancelReceptionAdmin($raId: Int!) {
        privAuthCancelReceptionAdmin(ra_id: $raId)
    }
`;
// 리셉션 > 비밀번호 변경
export const UPDATE_RECEPTION_ADMIN_PW = gql`
    mutation UpdateReceptionAdminPw(
        $currentPw: String
        $newPw: String
        $confirmPw: String
    ) {
        updateReceptionAdminPw(
            currentPw: $currentPw
            newPw: $newPw
            confirmPw: $confirmPw
        )
    }
`;
// 리셉션 > 방문자 상태 변경
export const UPDATE_VISITOR_STATUS_V2 = gql`
    mutation UpdateVisitorStatus_v2(
        $rvId: Int!
        $rvVisitorCardStatus: visitorCardStatus!
        $rvVisitorCardSerial: String!
        $rvCancelReason: String
    ) {
        updateVisitorStatus_v2(
            rv_id: $rvId
            rv_visitorCardStatus: $rvVisitorCardStatus
            rv_visitorCardSerial: $rvVisitorCardSerial
            rv_cancelReason: $rvCancelReason
        ) {
            tokenExpired
            result
        }
    }
`;

// 리셉션 > 무피플 상태 변경
export const UPDATE_MUPEOPLE_VISIT_CARD_STATUS = gql`
    mutation UpdateMuPeopleVisitCardStatus(
        $mpvcId: Int!
        $mpvcVisitCardStatus: visitorCardStatus!
        $mpvcVisitCardSerial: String!
        $mpvcCancelReason: String
    ) {
        updateMuPeopleVisitCardStatus(
            mpvc_id: $mpvcId
            mpvc_visitCardStatus: $mpvcVisitCardStatus
            mpvc_visitCardSerial: $mpvcVisitCardSerial
            mpvc_cancelReason: $mpvcCancelReason
        ) {
            tokenExpired
            result
        }
    }
`;
// 리셉션 > 주차 상태 변경
export const UPDATE_VISITOR_PARKING_STATUS_V2 = gql`
    mutation UpdateVisitorParkingStatus_v2(
        $rvId: Int!
        $rvCarRegistStatus: visitorParkingStatus!
    ) {
        updateVisitorParkingStatus_v2(
            rv_id: $rvId
            rv_carRegistStatus: $rvCarRegistStatus
        ) {
            tokenExpired
            result
        }
    }
`;
//  리셉션 > 배송신청 취소
export const UPDATE_SHIPPING_CANCEL = gql`
    mutation UpdateShippingCancel(
        $rsId: Int!
        $requestorType: Int!
        $cancelReason: String
    ) {
        updateShippingCancel(
            rsId: $rsId
            requestorType: $requestorType
            cancelReason: $cancelReason
        )
    }
`;
// 리셉션 > 배송신청 목록 다운로드
export const DOWN_SHIPPING_LIST = gql`
    mutation DownShippingList(
        $workSiteUnique: String!
        $searchDate: String!
        $shippingType: Int!
        $status: Int!
    ) {
        downShippingList(
            workSiteUnique: $workSiteUnique
            searchDate: $searchDate
            shippingType: $shippingType
            status: $status
        )
    }
`;
// 리셉션 > 배송신청 > 송장번호 수동 등록
export const UPDATE_TRACKING_NUMBER = gql`
    mutation UpdateTrackingNumber(
        $rsId: Int!
        $courierCompany: String!
        $trackingNumber: String!
        $pickupDate: String!
    ) {
        updateTrackingNumber(
            rsId: $rsId
            courierCompany: $courierCompany
            trackingNumber: $trackingNumber
            pickupDate: $pickupDate
        )
    }
`;
// 리셉션 > 택배 및 등기 수령정보 등록
export const CREATE_PARCEL_POST_RECEIVE = gql`
    mutation CreateParcelPostReceive(
        $workSiteUnique: String!
        $keepingDate: String!
        $type: Int!
        $receiverId: Int!
        $sender: String!
        $memo: String
    ) {
        createParcelPostReceive(
            workSiteUnique: $workSiteUnique
            keepingDate: $keepingDate
            type: $type
            receiverId: $receiverId
            sender: $sender
            memo: $memo
        )
    }
`;
// 리셉션 > 수령완료 상태 변경
export const UPDATE_PARCEL_POST_RECEIVE_STATUS = gql`
    mutation UpdateParcelPostReceiveStatus(
        $pprId: Int!
        $actualRecipientId: Int!
    ) {
        updateParcelPostReceiveStatus(
            pprId: $pprId
            actualRecipientId: $actualRecipientId
        )
    }
`;
// 리셉션 > 택배 및 등기 수령 목록 다운로드
export const DOWN_PARCEL_POST_RECEIVED_LIST = gql`
    mutation DownParcelPostReceivedList(
        $workSiteUnique: String!
        $schStartDate: String!
        $schEndDate: String!
        $type: Int!
        $status: Int!
    ) {
        downParcelPostReceivedList(
            workSiteUnique: $workSiteUnique
            schStartDate: $schStartDate
            schEndDate: $schEndDate
            type: $type
            status: $status
        )
    }
`;
// 리셉션 > Travel Kit 상태 변경
export const UPDATE_TK_RECEIVE_STATUS = gql`
    mutation UpdateTKReceiveStatus($tksId: Int!, $rentalStatus: Int!) {
        updateTKReceiveStatus(tksId: $tksId, rentalStatus: $rentalStatus)
    }
`;
// 리셉션 > 배포할 사원증 카드 등록(엑셀 업로드)
export const CREATE_EMPLOYEE_CARD = gql`
    mutation CreateEmployeeCard($uploadExcel: Upload!) {
        createEmployeeCard(uploadExcel: $uploadExcel)
    }
`;
// 리셉션 > 사원증 배포 수령경로 변경
export const UPDATE_EMPLOYEE_CARD_RECEIVED_STATUS = gql`
    mutation UpdateEmployeeCardReceivedStatus(
        $ecdId: Int!
        $receivedPath: Int!
        $receiverName: String
        $receiverTeam: String
        $receiverCellphone: String
    ) {
        updateEmployeeCardReceivedStatus(
            ecdId: $ecdId
            receivedPath: $receivedPath
            receiverName: $receiverName
            receiverTeam: $receiverTeam
            receiverCellphone: $receiverCellphone
        )
    }
`;
// 리셉션 > 사원증 배포 등록 양식 다운로드
export const DOWN_EMPLOYEE_CARD_DEPLOY_FORM = gql`
    mutation Mutation {
        downEmployeeCardDeployForm
    }
`;
