import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import * as R from './RegisterReceivedParcel.style';
import { SEE_EMPLOYEE_INFO } from '@graphql/queries';
import { CREATE_PARCEL_POST_RECEIVE } from '@graphql/mutations';
import { Query, Mutation, SeeEmployeeInfoCheck } from '@graphql/types';
import { SelectChangeEvent } from '@mui/material';
import { SERVICE_TYPE_OPTIONS } from '@constants/index';
import { getShippingType } from '@lib/enumChange';
import { selectedSite } from '@store/index';

import StyledInput from '@components/styled/StyledInput';
import StyledButton from '@components/styled/StyledButton';
import StyledComboBox from '@components/styled/StyledComboBox';
import MuiDatePicker from '@components/styled/MuiDatePicker';
import MuiSelect from '@components/styled/MuiSelect';

const RegisterReceivedParcel = () => {
    const navigate = useNavigate();
    const workSiteUnique = useReactiveVar(selectedSite);
    const [keepingDate, setKeepingDate] = useState<string | Date>(
        dayjs(new Date()).toISOString(),
    );

    const [searchTerm, setSearchTerm] = useState('');
    const [receiverId, setReceiverId] = useState<number | null>(null);
    const [info, setInfo] = useState({
        type: '택배',
        sender: '',
        memo: '',
    });

    const { data } = useQuery<Pick<Query, 'seeEmployeeInfo'>>(
        SEE_EMPLOYEE_INFO,
        {
            variables: {
                searchTerm,
            },
        },
    );
    const [createParcelPostReceive] = useMutation<
        Pick<Mutation, 'createParcelPostReceive'>
    >(CREATE_PARCEL_POST_RECEIVE);

    const infoUpdateHandler = useCallback(
        (
            e:
                | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
                | SelectChangeEvent<string>,
        ) => {
            const { name, value } = e.target;

            setInfo((prev) => {
                return {
                    ...prev,
                    [name]: value,
                };
            });
        },
        [],
    );

    const handleSelectedOption = (option: SeeEmployeeInfoCheck) => {
        setReceiverId(option?.user_id as number);
    };

    const submitHandler = async () => {
        const { type, sender, memo } = info;
        if (!receiverId) {
            toast.warning('수령예정인을 선택해주세요.');
            return;
        } else if (!sender) {
            toast.warning('발송인을 입력해주세요.');
            return;
        }
        await createParcelPostReceive({
            variables: {
                workSiteUnique,
                keepingDate,
                type: getShippingType(type),
                receiverId,
                sender,
                memo,
            },
            onCompleted: () => {
                toast.success('택배 및 등기 수령 정보를 입력하였습니다.');
                navigate('/parcel-recipiency');
            },
            onError: (err) => console.log(err.message),
        });
    };

    return (
        <R.Container>
            <R.InfoWrapper>
                <R.Title>택배 및 등기 수령 정보 입력하기</R.Title>
                <R.InfoArea>
                    <R.InfoType>수령정보</R.InfoType>
                    <R.InfoListArea>
                        <R.InfoList>
                            <R.InfoTitle>보관일</R.InfoTitle>
                            <MuiDatePicker
                                name="keepingDate"
                                value={keepingDate}
                                onChange={(date) => {
                                    setKeepingDate(date);
                                }}
                            />
                        </R.InfoList>
                        <R.InfoList>
                            <R.InfoTitle>구분</R.InfoTitle>
                            <MuiSelect
                                name="type"
                                value={info.type}
                                onChange={(e) => infoUpdateHandler(e)}
                                options={SERVICE_TYPE_OPTIONS}
                                width="440px"
                            />
                        </R.InfoList>
                        <R.InfoList>
                            <R.InfoTitle>수령예정인</R.InfoTitle>
                            <StyledComboBox
                                name="recipient"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                options={
                                    (data?.seeEmployeeInfo as SeeEmployeeInfoCheck[]) ||
                                    []
                                }
                                handleSelectOption={handleSelectedOption}
                                placeholder="수령예정인을 검색해주세요."
                                width="440px"
                            />
                        </R.InfoList>
                        <R.InfoList>
                            <R.InfoTitle>발송인</R.InfoTitle>
                            <StyledInput
                                name="sender"
                                value={info.sender}
                                onChange={(e) => infoUpdateHandler(e)}
                                width="calc(100% - 180px)"
                                placeholder="성함을 입력해주세요."
                            />
                        </R.InfoList>
                        <R.InfoList>
                            <R.InfoTitle>특이사항</R.InfoTitle>
                            <StyledInput
                                name="memo"
                                value={info.memo}
                                onChange={(e) => infoUpdateHandler(e)}
                                width="calc(100% - 180px)"
                                placeholder="특이사항을 입력해주세요."
                            />
                        </R.InfoList>
                    </R.InfoListArea>
                </R.InfoArea>
                <R.BtnArea>
                    <StyledButton
                        title="입력완료"
                        width="140px"
                        onClick={submitHandler}
                    />
                </R.BtnArea>
            </R.InfoWrapper>
        </R.Container>
    );
};

export default RegisterReceivedParcel;
