import { useState, useMemo, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';
import { IoAddSharp } from 'react-icons/io5';
import { LiaDownloadSolid } from 'react-icons/lia';
import { toast } from 'react-toastify';

import * as P from './ParcelShipping.style';
import { SEE_SHIPPING_LIST } from '@graphql/queries';
import { DOWN_SHIPPING_LIST } from '@graphql/mutations';
import { Query, Mutation, ReceptionAdmin, Shipping } from '@graphql/types';
import { userVar, selectedSite } from '@store/index';
import usePagination_v2 from '@hooks/usePagination_v2';
import { getShippingType, getShippingStatus } from '@lib/enumChange';
import { useAuth } from '@context/authProvider';
import { getResetDate } from '@utils/common';

import PaginationV2 from '@components/layout/PaginationV2';
import ShippingList from '@components/feature/ParcelShipping/ShippingList';
import Loader from '@components/share/Loader';
import MuiTableCalendar from '@components/styled/MuiTableCalendar';

const ParcelShipping = () => {
    const { userLogout } = useAuth();
    const navigate = useNavigate();
    const workSiteUnique = useReactiveVar(selectedSite);
    const [type, setType] = useState('택배');
    const [filter, setFilter] = useState('전체');
    const [searchDate, setSearchDate] = useState<string | Date>(
        dayjs(new Date()).toISOString(),
    );
    // 페이지네이션 관련 상태
    const [take, setTake] = useState(10);
    const [preCursor, setPreCursor] = useState(0);
    const [nextCursor, setNextCursor] = useState(0);
    const [cursorOption, setCursorOption] = useState<boolean | null>(null);

    const userInfo: ReceptionAdmin = useReactiveVar(userVar);
    const { receptionAdminPermission } = userInfo ?? {};
    const { rap_shipping } = receptionAdminPermission ?? {};

    // 접근 권한 없는 사용자 접근 막기
    useEffect(() => {
        if (!rap_shipping) {
            toast.warning('접근 권한이 없는 페이지 입니다.');
            navigate('/visitor');
        }
    }, [rap_shipping, navigate]);

    const { data, loading, refetch } = useQuery<Pick<Query, 'seeShippingList'>>(
        SEE_SHIPPING_LIST,
        {
            variables: {
                workSiteUnique,
                searchDate: getResetDate(searchDate),
                shippingType: getShippingType(type),
                status: getShippingStatus(filter),
                take,
                preCursor,
                nextCursor,
                cursorOption,
            },
            onError: (err) => {
                console.log(err.message);
            },
        },
    );
    const [downShippingList] =
        useMutation<Pick<Mutation, 'downShippingList'>>(DOWN_SHIPPING_LIST);

    const shippingList = data?.seeShippingList?.shippingList || [];
    const totalLength = data?.seeShippingList?.totalLength || 0;
    const totalCount = data?.seeShippingList?.totalCount || 0;
    const unsentCount = data?.seeShippingList?.unSentCount || 0;
    const pickupCount = data?.seeShippingList?.pickupCount || 0;
    const cancelCount = data?.seeShippingList?.cancelCount || 0;
    const completeCount = data?.seeShippingList?.completeCount || 0;

    const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setType(e.target.value);
        setFilter('전체');
        resetPaginationHandler();
    };

    const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
        resetPaginationHandler();
    };

    const uploadFileHandler = () => {
        // TODO: 추후 뮤테이션 추가
        console.log('upload');
    };

    const downloadFileHandler = async () => {
        await downShippingList({
            variables: {
                workSiteUnique,
                searchDate,
                shippingType: getShippingType(type),
                status: getShippingStatus(filter),
            },
            onCompleted: (data) => {
                window.open(data?.downShippingList as string);
            },
            onError: (err) => {
                console.log(err.message);
            },
        });
    };

    // 페이지네이션
    const {
        pageIndex,
        setPageIndex,
        pageGroupLength,
        prevPageHandler,
        nextPageHandler,
        prevPageGroupHandler,
        nextPageGroupHandler,
        resetPaginationHandler,
    } = usePagination_v2(
        take,
        totalLength,
        setPreCursor,
        setNextCursor,
        setCursorOption,
        shippingList,
        'rsId',
    );

    return (
        <P.Container>
            <P.Header>
                <P.TitleWrapper>
                    <P.TitleArea>
                        <P.Title>배송신청서</P.Title>
                        <P.Select
                            name="type"
                            id="type"
                            value={type}
                            onChange={handleTypeChange}
                        >
                            <P.Option value="택배">택배</P.Option>
                            <P.Option value="등기">등기</P.Option>
                            <P.Option value="행낭">행낭</P.Option>
                        </P.Select>
                    </P.TitleArea>
                    <P.BtnWrapper>
                        <P.LinkAddList htmlFor="parcelListExel">
                            <P.FileInput
                                type="file"
                                id="parcelListExel"
                                name="parcelListExel"
                                accept=".xlsx, .xls"
                                onChange={uploadFileHandler}
                            />
                            <IoAddSharp size={24} />
                        </P.LinkAddList>
                        <P.BtnDownload onClick={downloadFileHandler}>
                            <LiaDownloadSolid size={20} />
                        </P.BtnDownload>
                    </P.BtnWrapper>
                </P.TitleWrapper>
                <P.FilterWrapper>
                    <P.StatusWrapper>
                        <P.Label htmlFor="전체">
                            <P.Radio
                                type="radio"
                                id="전체"
                                name="shipping"
                                value="전체"
                                checked={filter === '전체'}
                                onChange={handleFilter}
                            />
                            <P.FilterText>전체 {totalCount}</P.FilterText>
                        </P.Label>
                        <P.Label htmlFor="미발송">
                            <P.Radio
                                type="radio"
                                id="미발송"
                                name="shipping"
                                value="미발송"
                                checked={filter === '미발송'}
                                onChange={handleFilter}
                            />
                            <P.FilterText>미발송 {unsentCount}</P.FilterText>
                        </P.Label>
                        <P.Label htmlFor="접수완료">
                            <P.Radio
                                type="radio"
                                id="접수완료"
                                name="shipping"
                                value="접수완료"
                                checked={filter === '접수완료'}
                                onChange={handleFilter}
                            />
                            <P.FilterText>접수완료 {pickupCount}</P.FilterText>
                        </P.Label>
                        <P.Label htmlFor="발송완료">
                            <P.Radio
                                type="radio"
                                id="발송완료"
                                name="shipping"
                                value="발송완료"
                                checked={filter === '발송완료'}
                                onChange={handleFilter}
                            />
                            <P.FilterText>
                                발송완료 {completeCount}
                            </P.FilterText>
                        </P.Label>
                        <P.Label htmlFor="취소">
                            <P.Radio
                                type="radio"
                                id="취소"
                                name="shipping"
                                value="취소"
                                checked={filter === '취소'}
                                onChange={handleFilter}
                            />
                            <P.FilterText>취소 {cancelCount}</P.FilterText>
                        </P.Label>
                    </P.StatusWrapper>
                    <MuiTableCalendar
                        value={searchDate}
                        onChange={setSearchDate}
                    />
                </P.FilterWrapper>
            </P.Header>
            <P.TableContainer>
                <ShippingList />
                {loading && <Loader />}
                {(shippingList as Shipping[])
                    .slice(pageIndex, pageIndex + take)
                    .map((list, idx: number) => (
                        <ShippingList
                            key={`${idx}-${list?.rsId}`}
                            list={list}
                            refetch={refetch}
                        />
                    ))}
                {!loading && !totalLength && (
                    <P.ListEmpty>조회된 리스트가 없습니다.</P.ListEmpty>
                )}
            </P.TableContainer>
            <PaginationV2
                take={take}
                setTake={setTake}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageGroupLength={pageGroupLength}
                totalLength={totalLength}
                prevPageHandler={prevPageHandler}
                nextPageHandler={nextPageHandler}
                prevPageGroupHandler={prevPageGroupHandler}
                nextPageGroupHandler={nextPageGroupHandler}
                resetPaginationHandler={resetPaginationHandler}
            />
        </P.Container>
    );
};

export default ParcelShipping;
