import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';

import theme from '@styles/theme';

export default function ResponsiveDatePickers({
    title,
    name,
    value,
    onChange,
    margin,
}: {
    title?: string;
    name?: string;
    value: string | Date;
    onChange: (value: string | Date) => void;
    margin?: string;
}) {
    return (
        <Wrapper $margin={margin}>
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={dayjs.locale('ko')}
            >
                <DemoContainer components={['DatePicker']}>
                    <DatePickerWrapper>
                        {title && <Title>{title}</Title>}
                        <StyledPicker
                            name={name}
                            format="YYYY-MM-DD"
                            defaultValue={dayjs(value)}
                            onChange={(newValue) =>
                                onChange(dayjs(newValue).toISOString())
                            }
                            autoFocus={false}
                        />
                    </DatePickerWrapper>
                </DemoContainer>
            </LocalizationProvider>
        </Wrapper>
    );
}

const Title = styled.div`
    margin-bottom: 8px;
`;

const Wrapper = styled.div<{ $margin?: string }>`
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
`;

const DatePickerWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const StyledPicker = styled(DatePicker)`
    width: 440px;
    height: 50px;

    .MuiInputBase-root {
        padding-right: 0 !important;
    }

    .MuiInputBase-input {
        height: 50px !important;
        box-sizing: border-box !important;
        cursor: pointer !important;
        border: ${theme.colors.componentBorder} 1px solid !important;
        border-radius: 4px;
        color: ${theme.colors.blackColor} !important;
        &:hover,
        &:active,
        &:focus {
            border: ${theme.colors.blackColor} 1px solid !important;
        }
    }
    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
    .MuiInputAdornment-root {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        margin-left: 0;
        width: 100%;
        height: 50px;
        max-height: 50px !important;
    }
    .MuiButtonBase-root {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 50px;
        transition: none;
        border-radius: 4px;
        border: transparent 1px solid !important;
        &:hover,
        &:focus,
        &:active {
            border: ${theme.colors.blackColor} 1px solid !important;
            background-color: transparent !important;
        }
        svg {
            width: 20px;
            color: ${theme.colors.grayFont};
        }
    }
    .MuiTouchRipple-root {
        display: none;
    }
`;
