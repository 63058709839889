import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { SelectChangeEvent } from '@mui/material';

import theme from '@styles/theme';
import { Query, Mutation, SeeEmployeeInfoCheck } from '@graphql/types';
import { UPDATE_EMPLOYEE_CARD_RECEIVED_STATUS } from '@graphql/mutations';
import {
    SEE_EMPLOYEE_CARD_DEPLOY_LIST,
    SEE_EMPLOYEE_INFO,
} from '@graphql/queries';
import { getCardAcceptStatus } from '@lib/enumChange';

import StyledModal from '@components/styled/StyledModal';
import StyledInput from '@components/styled/StyledInput';
import StyledButton from '@components/styled/StyledButton';
import StyledComboBox from '@components/styled/StyledComboBox';
import MuiSelect from '@components/styled/MuiSelect';
import MuiDatePicker from '@components/styled/MuiDatePicker';

type ReceiveInfoModalPropsType = {
    ecdId: number;
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReceiveInfoModal = ({
    ecdId,
    isModalOpen,
    setIsModalOpen,
}: ReceiveInfoModalPropsType) => {
    const [expectedDate, setExpectedDate] = useState<string | Date>(
        new Date().toISOString(),
    );
    const [searchTerm, setSearchTerm] = useState('');
    const [inputs, setInputs] = useState({
        receivedPath: '직접수령',
        receiverTeam: '',
        receiverName: '',
        receiverCellphone: '',
    });

    const { data } = useQuery<Pick<Query, 'seeEmployeeInfo'>>(
        SEE_EMPLOYEE_INFO,
        {
            variables: {
                searchTerm,
            },
            onError: (err) => console.log(err.message),
        },
    );

    const [updateEmployeeCardReceivedStatus] = useMutation<
        Pick<Mutation, 'updateEmployeeCardReceivedStatus'>
    >(UPDATE_EMPLOYEE_CARD_RECEIVED_STATUS);

    const changeHandler = useCallback(
        (
            e:
                | React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
                | SelectChangeEvent,
        ) => {
            const { value, name } = e.target;
            const { maxLength } = e.target as HTMLInputElement;

            if (name === 'receivedPath' && value === '직접수령') {
                setInputs((prev) => ({
                    ...prev,
                    [name]: value.slice(0, maxLength),
                    receiverTeam: '',
                    receiverName: '',
                    receiverCellphone: '',
                }));
                return;
            }

            setInputs((prev) => ({
                ...prev,
                [name]: value.slice(0, maxLength),
            }));
        },
        [],
    );

    const handleSelectedOption = (option: SeeEmployeeInfoCheck) => {
        setInputs((prev) => ({
            ...prev,
            receiverTeam: option.teamTF as string,
            receiverName: option.krName as string,
        }));
    };

    const submitHandler = async () => {
        const { receivedPath, receiverTeam, receiverName, receiverCellphone } =
            inputs;
        await updateEmployeeCardReceivedStatus({
            variables: {
                ecdId,
                receivedPath: getCardAcceptStatus(receivedPath),
                receiverName,
                receiverTeam,
                receiverCellphone,
            },
            refetchQueries: [SEE_EMPLOYEE_CARD_DEPLOY_LIST],
            onCompleted: () => {
                setIsModalOpen(false);
            },
            onError: (err) => console.log(err.message),
        });
    };

    return (
        <StyledModal
            title="배포 사원증 수령정보 등록"
            marginBottom={40}
            isOpen={isModalOpen}
            closeBtnHandler={() => setIsModalOpen(false)}
        >
            <FormWrapper onSubmit={(e) => e.preventDefault()}>
                <MuiSelect
                    title="수령경로"
                    name="receivedPath"
                    value={inputs.receivedPath}
                    onChange={changeHandler}
                    options={[
                        { name: '직접수령', value: '직접수령' },
                        { name: '대리수령', value: '대리수령' },
                    ]}
                    border={true}
                    width="440px"
                    margin="0 0 24px"
                />
                <MuiDatePicker
                    title="수령일"
                    value={expectedDate}
                    onChange={(date) => setExpectedDate(date)}
                    margin="0 0 24px"
                />
                <StyledComboBox
                    title="수령인"
                    name="recipient"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    options={
                        (data?.seeEmployeeInfo as SeeEmployeeInfoCheck[]) || []
                    }
                    handleSelectOption={handleSelectedOption}
                    placeholder="수령인을 검색해주세요."
                    width="440px"
                    margin="0 0 24px"
                    disabled={inputs.receivedPath === '직접수령'}
                />
                <StyledInput
                    type="number"
                    title="수령인 전화번호"
                    name="receiverCellphone"
                    value={inputs.receiverCellphone}
                    onChange={changeHandler}
                    width="440px"
                    placeholder="수령인의 전화번호를 입력해주세요."
                    maxLength={11}
                    disabled={inputs.receivedPath === '직접수령'}
                />
                <ButtonWrapper>
                    <StyledButton
                        title="취소"
                        width="140px"
                        fontColor={`${theme.colors.blackColor}`}
                        bgColor={`${theme.colors.whiteColor}`}
                        border={`${theme.colors.componentBorder} 1px solid`}
                        onClick={() => setIsModalOpen(false)}
                    />
                    <StyledButton
                        title="등록하기"
                        width="140px"
                        onClick={submitHandler}
                    />
                </ButtonWrapper>
            </FormWrapper>
        </StyledModal>
    );
};

export default ReceiveInfoModal;

const FormWrapper = styled.form`
    position: relative !important;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 8px;
    margin-top: 32px;
`;
