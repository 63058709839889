import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import theme from '@styles/theme';

import Header from '@components/layout/Header';
import SideNavigation from '@components/layout/SideNavigation';
import Login from '@pages/Login';
import Visitor from '@pages/Visitor';
import MuPeople from '@pages/MuPeople';
import Parking from '@pages/Parking';
import EmployeeCard from '@pages/EmployeeCard';
import ParcelShipping from '@pages/PackageShipping';
import RegisterTrackingNumber from '@pages/PackageShipping/RegisterTrackingNumber';
import ParcelReceived from '@pages/ParcelReceived';
import RegisterReceivedParcel from '@pages/RegisterReceivedParcel';
import TravelKit from '@pages/TravelKit';
import TravelKitDetail from '@pages/TravelKit/TravelKitDetail';
import NotFound from '@pages/NotFound';

const LoggedOutRoutes = memo(() => (
    <Routes>
        <Route path="/*" element={<Login />} />
    </Routes>
));

const LoggedInRoutes = memo(() => {
    return (
        <>
            <Header />
            <Container>
                <SideNavigation />
                <Routes>
                    <Route path="/visitor" element={<Visitor />} />
                    <Route path="/people" element={<MuPeople />} />
                    <Route path="/parking" element={<Parking />} />
                    <Route path="/employee-card" element={<EmployeeCard />} />
                    <Route
                        path="/parcel-shipment"
                        element={<ParcelShipping />}
                    />
                    <Route
                        path="/parcel-shipment/registration"
                        element={<RegisterTrackingNumber />}
                    />
                    <Route
                        path="/parcel-recipiency"
                        element={<ParcelReceived />}
                    />
                    <Route
                        path="/parcel-recipiency/registration"
                        element={<RegisterReceivedParcel />}
                    />
                    <Route path="/travelkit" element={<TravelKit />} />
                    <Route
                        path="/travelkit/detail"
                        element={<TravelKitDetail />}
                    />
                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </Container>
        </>
    );
});

const Router = ({ isLoggedIn = true }: { isLoggedIn: boolean }) => {
    if (!isLoggedIn) return <LoggedOutRoutes />;

    return <LoggedInRoutes />;
};

export default Router;

const Container = styled.section`
    display: flex;
    /* justify-content: space-between; */
    margin: 0 auto 100px;
    padding: 0 30px;
    width: 1600px;
    ${theme.device.pc} {
        padding: 0;
    }
`;
