type KeyValueStringType = Record<string, string>;

export const VISITOR_TYPE: KeyValueStringType = {
    visitor: '방문객',
    people: '무신사 피플',
} as const;

export const PATH_TYPE: KeyValueStringType = {
    walk: '도보',
    car: '차량',
} as const;

export const STATUS_TYPE: KeyValueStringType = {
    unprocessed: '미처리',
    processed: '방문증 발급',
    cancel: '취소',
    return: '방문증 회수',
} as const;

export const SERVICE_TYPE: KeyValueStringType = {
    registered: '등기',
    parcel: '택배',
} as const;

export const STATUS_OPTIONS: KeyValueStringType[] = [
    { name: '미처리', value: 'unprocessed' },
    { name: '방문증 발급', value: 'processed' },
    { name: '취소', value: 'cancel' },
    { name: '방문증 회수', value: 'return' },
];

export const INITIAL_STATUS_OPTIONS: KeyValueStringType[] = [
    { name: '미처리', value: 'unprocessed' },
    { name: '방문증 발급', value: 'processed' },
    { name: '취소', value: 'cancel' },
];

export const PROCESSED_STATUS_OPTIONS: KeyValueStringType[] = [
    { name: '방문증 발급', value: 'processed' },
    { name: '방문증 회수', value: 'return' },
];

export const PARKING_STATUS_OPTIONS: KeyValueStringType[] = [
    { name: '미등록', value: 'unprocessed' },
    { name: '등록완료', value: 'complete' },
    { name: '취소', value: 'cancel' },
];

export const SERVICE_TYPE_OPTIONS: KeyValueStringType[] = [
    { name: '택배', value: '택배' },
    { name: '등기', value: '등기' },
];

export const PARCEL_SHIPPING_STATUS_OPTIONS: KeyValueStringType[] = [
    { name: '미발송', value: '미발송' },
    { name: '취소', value: '취소' },
];

export const EMPLOYEE_CARD_STATUS_OPTIONS: KeyValueStringType[] = [
    { name: '미배포', value: 'unprocessed' },
    { name: '직접수령', value: 'direct' },
    { name: '대리수령', value: 'deputy' },
];

export const TRAVEL_KIT_RENTAL_STATUS: KeyValueStringType[] = [
    { name: '보유', value: '보유' },
    { name: '승인대기', value: '승인대기' },
    { name: '예약', value: '예약' },
    { name: '대여', value: '대여' },
    { name: '미반납', value: '미반납' },
    { name: '검수', value: '검수' },
    { name: '점검', value: '점검' },
];
