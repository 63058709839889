import { useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';

import theme from '@styles/theme';

import StyledModal from '@components/styled/StyledModal';
import StyledInput from '@components/styled/StyledInput';
import StyledButton from '@components/styled/StyledButton';

type RequestCancelModalProps = {
    status: string;
    reason: string;
    setStatus: React.Dispatch<React.SetStateAction<string>>;
    setCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
    setReason: React.Dispatch<React.SetStateAction<string>>;
    submitHandler: (status: string) => void;
    customStatus?: string;
};

const RequestCancelModal = ({
    status,
    reason,
    setStatus,
    setCancelModal,
    setReason,
    submitHandler,
    customStatus,
}: RequestCancelModalProps) => {
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        ref.current!.focus();
    }, []);

    const closeModalHandler = () => {
        setStatus(customStatus ? customStatus : 'unprocessed');
        setCancelModal(false);
        setReason('');
    };

    const onSubmit = () => {
        if (!reason) {
            alert('취소 사유를 입력해주세요.');
            return;
        }
        submitHandler(status);
        setCancelModal(false);
    };

    return (
        <StyledModal
            title="취소"
            marginBottom={40}
            isOpen={status === 'cancel' || status === '취소'}
            closeBtnHandler={closeModalHandler}
        >
            <StyledInput
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="취소 사유를 입력해주세요"
                ref={ref}
            />
            <ButtonWrapper>
                <StyledButton
                    title="취소"
                    width="140px"
                    fontColor={`${theme.colors.blackColor}`}
                    bgColor={`${theme.colors.whiteColor}`}
                    border={`${theme.colors.componentBorder} 1px solid`}
                    onClick={closeModalHandler}
                />
                <StyledButton
                    title="취소하기"
                    width="140px"
                    onClick={onSubmit}
                />
            </ButtonWrapper>
        </StyledModal>
    );
};

export default RequestCancelModal;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 8px;
    margin-top: 32px;
`;
