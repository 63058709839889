import styled from 'styled-components';
import theme from '@styles/theme';

export const Container = styled.main`
    padding-top: 100px;
    width: calc(100% - 240px);
`;

export const InfoWrapper = styled.section`
    width: 100%;
    max-width: 620px;
`;

export const Title = styled.h2`
    font-size: 32px;
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

export const InfoArea = styled.div`
    margin-top: 32px;
`;

export const InputArea = styled.div`
    margin-top: 40px;
`;

export const InfoType = styled.span`
    display: block;
    margin-bottom: 24px;
    font-size: 18px;
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

export const InfoListArea = styled.ul``;

export const InfoList = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

export const InfoInput = styled(InfoList)`
    margin-bottom: 12px;
`;

export const InfoTitle = styled.span`
    display: block;
    width: 180px;
    color: ${theme.colors.grayFont};
`;

export const InfoTitleBold = styled(InfoTitle)`
    color: ${theme.colors.blackColor};
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

export const InfoText = styled.span<{ $fontColor?: string }>`
    width: 440px;
    font-family: AppleSDGothicNeoMedium, sans-serif;
    color: ${({ $fontColor }) =>
        $fontColor ? $fontColor : theme.colors.blackColor};
`;

export const BtnArea = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    padding-bottom: 100px;
`;

export const Description = styled.div`
    margin-left: 180px;
    width: 440px;
    font-family: AppleSDGothicNeoMedium, sans-serif;
    color: ${theme.colors.grayIconColor};
    font-size: 12px;
`;
