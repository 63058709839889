import {
    memo,
    RefObject,
    useState,
    useRef,
    useCallback,
    useEffect,
} from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

import theme from '@styles/theme';

import StyledInput from './StyledInput';

type StyledComboBoxProps = {
    title?: string;
    width?: string;
    height?: number;
    margin?: string;
    options?: any[];
    name?: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectOption: (option: any) => void;
    handleDeleteOption?: (option: any) => void;
    selectedList?: any[];
    placeholder?: string;
    disabled?: boolean;
};

const StyledComboBox = ({
    title,
    width,
    height = 50,
    margin,
    options = [],
    name = '',
    value = '',
    onChange,
    handleSelectOption,
    handleDeleteOption,
    selectedList,
    placeholder = '',
    disabled,
}: StyledComboBoxProps) => {
    const inputRef = useRef<HTMLInputElement>(
        null,
    ) as RefObject<HTMLInputElement>;
    const optionRef = useRef<HTMLDivElement>(null) as RefObject<HTMLDivElement>;
    const [optionVisible, setOptionVisible] = useState(false);

    const onClickOption = useCallback(
        (option: any) => {
            handleSelectOption(option);
            setOptionVisible(false);
        },
        [handleSelectOption],
    );

    const handleClickOutside = useCallback(
        (e: MouseEvent) => {
            let boxRefCurrent =
                inputRef.current &&
                inputRef?.current.contains(e.target as HTMLElement);
            let optRefCurrent =
                optionRef.current &&
                optionRef?.current.contains(e.target as HTMLElement);

            if (!boxRefCurrent && !optRefCurrent) setOptionVisible(false);
        },
        [inputRef, optionRef],
    );

    useEffect(() => {
        if (options?.length > 0) {
            setOptionVisible(true);
        }
    }, [options]);

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => window.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside]);

    return (
        <Wrapper $margin={margin}>
            {title && <Title>{title}</Title>}
            <InputComboBox $width={width}>
                <StyledInput
                    ref={inputRef}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onClick={() => setOptionVisible(!optionVisible)}
                    placeholder={placeholder}
                    disabled={disabled}
                    SEARCH
                />
                {optionVisible && options.length !== 0 && (
                    <ComboBox ref={optionRef}>
                        {options.map((option: any, index: number) => (
                            <ComboList
                                key={`${index}-option`}
                                onClick={() => onClickOption(option)}
                            >
                                {option.teamTF} {option.krName}
                            </ComboList>
                        ))}
                    </ComboBox>
                )}
            </InputComboBox>
        </Wrapper>
    );
};

export default memo(StyledComboBox);

const Wrapper = styled.div<{ $margin?: string }>`
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
`;

const Title = styled.div`
    margin-bottom: 8px;
`;

const InputComboBox = styled.div<{ $width?: string; $margin?: string }>`
    position: relative;
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
    width: ${({ $width }) => ($width ? $width : 'auto')};
`;

const ComboBox = styled.div`
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    max-height: 200px;
    background-color: ${theme.colors.whiteColor};
    border-radius: 8px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.3);
    overflow-y: scroll;
    z-index: 10;
`;

const ComboList = styled.div`
    padding: 15px 12px;
    width: 100%;
    background-color: ${theme.colors.whiteColor};
    cursor: pointer;
    &:hover {
        background-color: ${darken(0.05, theme.colors.whiteColor)};
    }
`;
