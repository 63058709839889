import { useMutation } from '@apollo/client';
import { useState, useEffect, memo } from 'react';
import { createPortal } from 'react-dom';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import theme from '@styles/theme';
import { UPDATE_PARCEL_POST_RECEIVE_STATUS } from '@graphql/mutations';
import { Query, Mutation, ParcelPostReceived } from '@graphql/types';
import { getDDay, handleTextLength } from '@utils/common';
import { getShippingType } from '@lib/enumChange';

import AddRecipientPopup from './AddRecipientPopup';

type ReceivedListProps = {
    list?: ParcelPostReceived;
    refetch?: (
        variables?: Partial<OperationVariables>,
    ) => Promise<ApolloQueryResult<Pick<Query, 'seeParcelPostReceivedList'>>>;
};

const ReceivedList = ({ list, refetch }: ReceivedListProps) => {
    const [recipientPopup, setRecipientPopup] = useState(false);
    const [updateParcelPostReceiveStatus] = useMutation<
        Pick<Mutation, 'updateParcelPostReceiveStatus'>
    >(UPDATE_PARCEL_POST_RECEIVE_STATUS);
    const {
        pprId,
        status,
        type,
        keepingDate,
        teamName,
        recipient,
        sender,
        memo,
        receiveDate,
        actualRecipient,
    } = list ?? {};

    const openModalHandler = () => {
        if (actualRecipient) return;
        setRecipientPopup(true);
    };

    const submitHandler = async (receiverId: number) => {
        if (!receiverId) {
            toast.warning('수령인을 선택해주세요.');
            return;
        }
        await updateParcelPostReceiveStatus({
            variables: {
                pprId,
                actualRecipientId: receiverId,
            },
            onCompleted: () => {
                toast.success('수령인이 등록되었습니다.');
                refetch?.();
            },
        });
        setRecipientPopup(false);
    };

    if (!list)
        return (
            <TableTitle>
                <CellStatus>상태</CellStatus>
                <CellType>종류</CellType>
                <CellKeepingDate>요청일시</CellKeepingDate>
                <CellReceiverTeam>소속팀</CellReceiverTeam>
                <CellReceiver>성함</CellReceiver>
                <CellSender>발송인</CellSender>
                <CellMemo>특이사항</CellMemo>
                <CellReceivedDate>수령일</CellReceivedDate>
                <CellManager>수령인</CellManager>
            </TableTitle>
        );

    return (
        <>
            <Container onClick={openModalHandler}>
                <CellStatus>{status === 2 ? '수령완료' : '미수령'}</CellStatus>
                <CellType>
                    {type ? getShippingType(type as number) : '-'}
                </CellType>
                <CellKeepingDate>
                    {keepingDate
                        ? `${dayjs(keepingDate).format(
                              'YYYY-MM-DD HH:MM',
                          )} (${getDDay(keepingDate as string)})`
                        : '-'}
                </CellKeepingDate>
                <CellReceiverTeam>
                    {teamName ? handleTextLength(teamName, 8) : '-'}
                </CellReceiverTeam>
                <CellReceiver>{recipient ? recipient : '-'}</CellReceiver>
                <CellSender>{sender ? sender : '-'}</CellSender>
                <CellMemo>{memo ? handleTextLength(memo, 24) : '-'}</CellMemo>
                <CellReceivedDate>
                    {receiveDate
                        ? dayjs(receiveDate).format('YYYY-MM-DD HH:MM')
                        : '-'}
                </CellReceivedDate>
                <CellManager>
                    {actualRecipient ? actualRecipient : '-'}
                </CellManager>
            </Container>
            {createPortal(
                recipientPopup && (
                    <AddRecipientPopup
                        recipientPopup={recipientPopup}
                        setRecipientPopup={setRecipientPopup}
                        submitHandler={submitHandler}
                    />
                ),
                document.body,
            )}
        </>
    );
};

export default memo(ReceivedList);

const Container = styled.li`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.componentBorder} 1px solid;
    cursor: pointer;
`;

const TableTitle = styled.li`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.blackColor} 1px solid;
`;

const TableCell = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    height: inherit;
    text-align: center;
    word-break: keep-all;
    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.5; */
`;

const CellStatus = styled(TableCell)`
    flex: 7%;
`;
const CellType = styled(TableCell)`
    flex: 6%;
`;

const CellKeepingDate = styled(TableCell)`
    flex: 15%;
`;

const CellReceiverTeam = styled(TableCell)`
    flex: 10%;
`;

const CellReceiver = styled(TableCell)`
    flex: 6%;
`;

const CellSender = styled(TableCell)`
    flex: 7%;
`;

const CellMemo = styled(TableCell)`
    flex: 25%;
`;

const CellReceivedDate = styled(TableCell)`
    flex: 13%;
`;

const CellManager = styled(TableCell)`
    flex: 11%;
`;
