import {
    ApolloClient,
    InMemoryCache,
    from,
    ApolloError,
    ApolloLink,
} from '@apollo/client';
import { HttpLink } from '@apollo/client/link/http';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { GraphQLError } from 'graphql';
import { toast } from 'react-toastify';
import ApolloLinkTimeout from 'apollo-link-timeout';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { PRIV_AUTH_CANCEL_RECEPTION_ADMIN } from '@graphql/mutations';

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
            const { message, locations, path } = error;

            console.info(
                `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                    locations,
                )}, Path: ${path}`,
            );
            errorHandler(error as GraphQLError);
        });
    }

    if (networkError) {
        console.info(`[Network error]: ${networkError}`);
        if (networkError.message === 'Timeout exceeded') {
            toast.error('요청 시간이 지났습니다. 잠시후 다시 시도해주세요.');
        }
    }
});

const timeoutLink = new ApolloLinkTimeout(30000);

const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_SERVER_URL,
    credentials: 'same-origin',
    headers: {
        Authorization: sessionStorage.getItem('musinsa_reception_token')
            ? `Bearer ${sessionStorage.getItem('musinsa_reception_token')}`
            : '',
        'Apollo-Require-Preflight': 'true',
    },
}) as unknown as ApolloLink;
// const authLink = setContext((_, { headers }) => {
//     const token = sessionStorage.getItem('musinsa_reception_token');
//     return {
//         headers: {
//             ...headers,
//             authorization: token ? `Bearer ${token}` : '',
//         },
//     };
// });

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//     if (graphQLErrors)
//         graphQLErrors.forEach((error) => {
//             const { message, locations, path } = error;
//             console.error(
//                 `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
//             );
//             errorHandler(error as GraphQLError);
//         });
//     if (networkError) {
//         console.error(`[Network error]: ${networkError}`);
//     }
// });

// // HTTP link talks to the server
// const httpLink = new HttpLink({
//     uri: process.env.REACT_APP_SERVER_URL,
//     headers: {
//         'Apollo-Require-Preflight': 'true',
//     },
// });

const client = new ApolloClient({
    link: from([errorLink, timeoutLink, uploadLink as ApolloLink]),
    // link: from([authLink, errorLink, httpLink]),
    connectToDevTools: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
        },
    },
    cache: new InMemoryCache(),
});

export default client;

const handleLogout = async () => {
    try {
        const { data } = await client.mutate({
            mutation: PRIV_AUTH_CANCEL_RECEPTION_ADMIN,
            variables: { raId: Number(sessionStorage.getItem('userId')) },
        });
        if (data.privAuthCancelReceptionAdmin) {
            console.log('Logout successful', data);
            sessionStorage.removeItem('site');
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('musinsa_reception_token');
            window.location.href = '/';
        }
    } catch (error) {
        console.error('Error during logout', error);
    }
};

//에러 핸들링
const errorHandler = (error: ApolloError | GraphQLError | Error) => {
    const { path } = error as GraphQLError;
    switch (error.message) {
        case 'err_01':
            if (path?.[0] === 'updateReceptionAdminPw') {
                // 리셉션 관리자 비밀번호 변경
                toast.error('입력한 현재 비밀번호가 틀립니다.');
            } else {
                // default
                toast.error('리셉션관리자만 사용할 수 있는 기능입니다.');
            }
            break;

        case 'err_02':
            if (path?.[0] === 'authenticateReceptionAdmin') {
                // 리셉션 개인정보조회 인증
                toast.error('존재하지 않는 계정입니다. 다시 입력해주세요.');
            } else if (path?.[0] === 'updateReceptionAdminPw') {
                // 리셉션 관리자 비밀번호 변경
                toast.error(
                    '현재 비밀번호와 변경할 비밀번호가 동일합니다. 다른 비밀번호를 입력해주세요.',
                );
            } else if (
                path?.[0] === 'updateVisitorStatus_v2' ||
                path?.[0] === 'updateMuPeopleVisitCardStatus'
            ) {
                // 방문자 상태 변경(방문증입력), 무신사 피플 방문증 대여상태 변경 (방문증 번호 입력)
                toast.error(
                    '현재 사용 불가능한 카드 번호입니다. 다시 확인하고 입력해주세요.',
                );
            } else if (
                path?.[0] === 'seeShippingList' ||
                path?.[0] === 'seeParcelPostReceivedList' ||
                path?.[0] === 'seeShippingDetail'
            ) {
                // 배송 신청서 목록, 택배 및 등기 수령 목록 조회
                toast.error('조회에 실패하였습니다.');
            } else if (path?.[0] === 'downShippingList') {
                // 배송 신청서 목록 다운로드
                toast.error('배송 신청서 목록 다운로드에 실패하였습니다.');
            } else if (path?.[0] === 'updateTrackingNumber') {
                // 송장번호 업데이트
                toast.error('송장번호 업데이트에 실패하였습니다.');
            } else if (path?.[0] === 'createParcelPostReceive') {
                // 택배 및 등기 수령정보 등록
                toast.error('택배 및 등기 수령정보 등록에 실패하였습니다.');
            } else if (path?.[0] === 'downParcelPostReceivedList') {
                // 택배 및 등기 수령 목록 다운로드
                toast.error(
                    '택배 및 등기 수령 목록 다운로드에 실패하였습니다.',
                );
            } else if (path?.[0] === 'updateEmployeeCardReceivedStatus') {
                // 사원증 수령 상태 업데이트
                toast.error('수정에 실패하였습니다.');
            } else if (path?.[0] === 'createEmployeeCard') {
                // 택배 및 등기 수령 목록 다운로드
                toast.error('배포할 사원증 카드 등록에 실패하였습니다.');
            } else if (path?.[0] === 'downEmployeeCardDeployForm') {
                // 택배 및 등기 수령 목록 다운로드
                toast.error('다운로드에 실패하였습니다.');
            } else {
                // default
                toast.error('로그인에 실패하였습니다.');
            }
            break;

        case 'err_03':
            if (path?.[0] === 'authenticateReceptionAdmin') {
                // 리셉션 개인정보조회 인증
                toast.error(
                    '로그인한 계정과 인증시도 계정이 일치하지 않습니다.',
                );
            } else if (path?.[0] === 'updateReceptionAdminPw') {
                // 리셉션 관리자 비밀번호 변경
                toast.error('새 비밀번호와 확인 비밀번호가 다릅니다.');
            } else if (
                path?.[0] === 'updateVisitorStatus_v2' ||
                path?.[0] === 'updateMuPeopleVisitCardStatus'
            ) {
                // 방문자 상태 변경(방문증입력), 무신사 피플 방문증 대여상태 변경 (방문증 번호 입력)
                toast.error(
                    '등록되지 않은 방문자 카드번호입니다. 관리자에게 문의하세요.',
                );
            } else {
                // default
                toast.error(
                    '비밀번호 변경일이 90일이 넘었습니다. 비밀번호를 변경해주세요.',
                );
            }
            break;

        case 'err_04':
            if (path?.[0] === 'authenticateReceptionAdmin') {
                // 리셉션 개인정보조회 인증
                toast.error('비밀번호가 일치하지 않습니다.');
            } else if (path?.[0] === 'updateReceptionAdminPw') {
                // 리셉션 관리자 비밀번호 변경
                toast.error(
                    '비밀번호 변경 후 1일이 지난 후 다시 변경 가능합니다.',
                );
            } else {
                // default
                toast.error(
                    '1개월 이상 로그인 기록이 없습니다. 관리자에게 문의하세요.',
                );
            }
            break;

        case 'err_05':
            toast.error('10분 뒤 로그인하실 수 있습니다.');
            break;
        case 'err_09':
            toast.error('관리자만 사용할 수 있는 기능입니다.');
            break;
        case 'err_71':
            toast.error('배송 신청 취소에 실패하였습니다.');
            break;
        case 'err_90':
            toast.error('먼저 로그인이 필요합니다.');
            break;
        case 'err_91':
            toast.error('존재하지 않는 계정입니다.');
            break;
        case 'err_99':
            toast.error('다른곳에서 로그인을 하였습니다.');
            handleLogout();
            break;
        default:
            toast.error(`server : ${error.message}`);
    }
};
