import { memo, useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import theme from '@styles/theme';
import { SEE_EMPLOYEE_INFO } from '@graphql/queries';
import { Query, SeeEmployeeInfoCheck } from '@graphql/types';

import StyledModal from '@components/styled/StyledModal';
import StyledButton from '@components/styled/StyledButton';
import StyledComboBox from '@components/styled/StyledComboBox';

type AddRecipientPopupProps = {
    recipientPopup: boolean;
    setRecipientPopup: React.Dispatch<React.SetStateAction<boolean>>;
    submitHandler: (id: number) => void;
};

const AddRecipientPopup = ({
    recipientPopup,
    setRecipientPopup,
    submitHandler,
}: AddRecipientPopupProps) => {
    const [receiverId, setReceiverId] = useState<number | null>(null);
    const [searchTerm, setSearchTerm] = useState('');

    const { data } = useQuery<Pick<Query, 'seeEmployeeInfo'>>(
        SEE_EMPLOYEE_INFO,
        {
            variables: {
                searchTerm,
            },
        },
    );

    const handleSelectedOption = (option: SeeEmployeeInfoCheck) => {
        setReceiverId(option?.user_id as number);
    };

    const closeModalHandler = useCallback(() => {
        setRecipientPopup(false);
    }, [setRecipientPopup]);

    return (
        <StyledModal
            title="수령인 입력"
            marginBottom={32}
            isOpen={recipientPopup}
            closeBtnHandler={closeModalHandler}
        >
            <StyledComboBox
                name="recipient"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                options={
                    (data?.seeEmployeeInfo as SeeEmployeeInfoCheck[]) || []
                }
                handleSelectOption={handleSelectedOption}
                placeholder="수령인을 검색해주세요."
                width="440px"
            />
            <ButtonWrapper>
                <StyledButton
                    title="취소"
                    width="140px"
                    fontColor={`${theme.colors.blackColor}`}
                    bgColor={`${theme.colors.whiteColor}`}
                    border={`${theme.colors.componentBorder} 1px solid`}
                    onClick={closeModalHandler}
                />
                <StyledButton
                    title="저장하기"
                    width="140px"
                    onClick={() => submitHandler(receiverId as number)}
                />
            </ButtonWrapper>
        </StyledModal>
    );
};

export default memo(AddRecipientPopup);

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 8px;
    margin-top: 22px;
`;
