import { useMutation } from '@apollo/client';
import { useState, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import { SelectChangeEvent } from '@mui/material';
import dayjs from 'dayjs';

import theme from '@styles/theme';
import { UPDATE_TK_RECEIVE_STATUS } from '@graphql/mutations';
import { Mutation, RentedTravelKit } from '@graphql/types';
import { getTKRentalStatus, getTKType } from '@lib/enumChange';
import { TRAVEL_KIT_RENTAL_STATUS } from '@constants/index';
import { handleTextLength } from '@utils/common';

import MuiSelect from '@components/styled/MuiSelect';

type TravelKitListProps = {
    list?: RentedTravelKit;
    refetch?: () => void;
};

const TravelKitList = ({ list, refetch }: TravelKitListProps) => {
    const navigate = useNavigate();
    const [status, setStatus] = useState('');

    const [updateTKReceiveStatus] = useMutation<
        Pick<Mutation, 'updateTKReceiveStatus'>
    >(UPDATE_TK_RECEIVE_STATUS);

    const {
        tksId,
        rentalStatus,
        tkType,
        tkName,
        component,
        rentalStartDate,
        rentalEndDate,
        userTeam,
        userName,
        reqText,
    } = list ?? {};

    useEffect(() => {
        setStatus(getTKRentalStatus(rentalStatus as number) as string);
    }, [rentalStatus]);

    if (!list)
        return (
            <TableTitle>
                <CellStatus>상태</CellStatus>
                <CellType>종류</CellType>
                <CellItemName>구성품명</CellItemName>
                <CellDescription>구성 설명</CellDescription>
                <CellRentalPeriod>대여기한</CellRentalPeriod>
                <CellRequestor>요청자</CellRequestor>
                <CellReason>대여사유</CellReason>
            </TableTitle>
        );

    const changeSelectHandler = async (
        e:
            | React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
            | SelectChangeEvent,
    ) => {
        const { value } = e.target;
        setStatus(value);
        submitHandler(value);
    };

    const submitHandler = async (status: string) => {
        // if ((rentalStatus as number) > (getTKRentalStatus(status) as number)) {
        //     toast.warning('이전 단계로는 변경할 수 없습니다.');
        //     setStatus(getTKRentalStatus(rentalStatus as number) as string);
        //     return;
        // }
        if (!window.confirm('상태를 변경하시겠습니까?')) {
            setStatus(getTKRentalStatus(rentalStatus as number) as string);
            return;
        }

        await updateTKReceiveStatus({
            variables: {
                tksId,
                rentalStatus: getTKRentalStatus(status) as number,
            },
            onCompleted: () => {
                refetch!();
                toast.success('Travel Kit 대여 상태를 변경했습니다.');
            },
            onError: (err) => {
                console.log(err.message);
                setStatus(getTKRentalStatus(rentalStatus as number) as string);
            },
        });
    };
    const handleMoveToRegistration = () => {
        navigate('/travelkit/detail', {
            state: {
                tksId,
            },
        });
    };

    return (
        <Container onClick={handleMoveToRegistration}>
            <CellStatus>
                <MuiSelect
                    name="status"
                    value={status}
                    onChange={(e) => changeSelectHandler(e)}
                    onClick={(e) => e.stopPropagation()}
                    options={TRAVEL_KIT_RENTAL_STATUS}
                    border={false}
                    optionsDisabledList={['승인대기', '예약', '미반납']}
                />
            </CellStatus>
            <CellType>{tkType ? getTKType(tkType as number) : '-'}</CellType>
            <CellItemName>{tkName ? tkName : '-'}</CellItemName>
            <CellDescription>
                {component ? handleTextLength(component, 28) : '-'}
            </CellDescription>
            <CellRentalPeriod
                $fontColor={
                    rentalStatus === 5
                        ? theme.colors.caution
                        : theme.colors.blackColor
                }
            >
                {rentalStartDate
                    ? `${dayjs(rentalStartDate).format('YYYY-MM-DD')} ~ ${dayjs(
                          rentalEndDate,
                      ).format('YYYY-MM-DD')}`
                    : '-'}
            </CellRentalPeriod>
            <CellRequestor>
                {userName
                    ? `${handleTextLength(
                          userTeam as string,
                          10,
                      )} - ${userName}`
                    : '-'}
            </CellRequestor>
            <CellReason>
                {reqText ? handleTextLength(reqText, 20) : '-'}
            </CellReason>
        </Container>
    );
};

export default memo(TravelKitList);

const Container = styled.li<{ $disabled?: boolean }>`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.componentBorder} 1px solid;
    cursor: pointer;
    ${({ $disabled }) =>
        $disabled &&
        css`
            opacity: 0.3;
            cursor: auto;
        `};

    ${({ $disabled }) =>
        !$disabled &&
        css`
            &:hover {
                background-color: ${theme.colors.paginationBorder};
            }
        `};
`;

const TableTitle = styled.li`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.blackColor} 1px solid;
`;

const TableCell = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    height: inherit;
    text-align: center;
    word-break: keep-all;
    line-height: 1.2;
    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.5; */
`;

const CellStatus = styled(TableCell)`
    flex: 8%;
`;
const CellType = styled(TableCell)`
    flex: 8%;
`;

const CellItemName = styled(TableCell)`
    flex: 11%;
`;

const CellDescription = styled(TableCell)`
    flex: 24%;
`;

const CellRentalPeriod = styled(TableCell)<{ $fontColor?: string }>`
    flex: 15%;
    color: ${({ $fontColor }) =>
        $fontColor ? $fontColor : theme.colors.blackColor};
`;

const CellRequestor = styled(TableCell)`
    flex: 16%;
`;

const CellReason = styled(TableCell)`
    flex: 18%;
`;
