import { useQuery, useReactiveVar } from '@apollo/client';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { io } from 'socket.io-client';

import AuthProvider from '@context/authProvider';
import GlobalStyles from '@styles/GlobalStyles';
import theme from '@styles/theme';
import {
    SEE_RECEPTION_ADMIN,
    SEE_RECEPTION_ADMIN_SITE_LIST,
} from '@graphql/queries';
import {
    userVar,
    siteVar,
    selectedSite,
    newVisitor,
    newMuPeople,
    newParking,
} from '@store';
import { Query, ReceptionAdmin } from '@graphql/types';
import { useAuth } from '@context/authProvider';

import Router from './Router';

function App() {
    const { userLogout } = useAuth();
    const { pathname } = useLocation();
    const userInfo: ReceptionAdmin = useReactiveVar(userVar);
    const selectSiteUnique: string = useReactiveVar(selectedSite);
    const isLoggedIn = sessionStorage.getItem('musinsa_reception_token')
        ? true
        : false;
    const [socket, setSocket] = useState<any>(null);
    const [idleTime, setIdleTime] = useState(0);

    // 로그인 사용자 정보
    useQuery<Pick<Query, 'seeReceptionAdmin'>>(SEE_RECEPTION_ADMIN, {
        skip: !isLoggedIn,
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            userVar(data.seeReceptionAdmin?.receptionAdmin);
            sessionStorage.setItem(
                'userId',
                (
                    data.seeReceptionAdmin?.receptionAdmin?.ra_id as number
                )?.toString(),
            );
        },
        onError: (err) => {
            console.log(err.message);
        },
    });

    // 무신사 캠퍼스 리스트
    useQuery<Pick<Query, 'seeReceptionAdminStieList'>>(
        SEE_RECEPTION_ADMIN_SITE_LIST,
        {
            skip: !isLoggedIn,
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                if (!data.seeReceptionAdminStieList?.tokenExpired as boolean)
                    userLogout();
                siteVar(data.seeReceptionAdminStieList?.receptionAdminSiteList);
                if (!sessionStorage.getItem('site')) {
                    selectedSite(
                        data?.seeReceptionAdminStieList
                            ?.receptionAdminSiteList?.[0]?.siteUnique,
                    );
                    sessionStorage.setItem(
                        'site',
                        data?.seeReceptionAdminStieList
                            ?.receptionAdminSiteList?.[0]?.siteUnique as string,
                    );
                } else {
                    selectedSite(sessionStorage.getItem('site'));
                }
            },
            onError: (err) => {
                console.log(err.message);
            },
        },
    );

    // socket io 설정
    useEffect(() => {
        if (isLoggedIn) {
            const newSocket = io(`${process.env.REACT_APP_SOCKET_IP}`, {
                path: '/musinsaES',
                query: {
                    workSiteUnique: selectSiteUnique,
                    userType: userInfo.userType,
                },
            });
            setSocket(newSocket);
        }
    }, [userInfo, selectSiteUnique, isLoggedIn]);

    useEffect(() => {
        if (!socket) return;

        socket.on('registVisitor', (res: string) => {
            const data = JSON.parse(res);

            if (data.registered) {
                newVisitor(true);

                setTimeout(() => {
                    newVisitor(false);
                }, 800);
            }
        });

        socket.on('registMuPeople', (res: string) => {
            const data = JSON.parse(res);

            if (data.registered) {
                newMuPeople(true);

                setTimeout(() => {
                    newMuPeople(false);
                }, 800);
            }
        });

        socket.on('registParking', (res: string) => {
            const data = JSON.parse(res);

            if (data.registered) {
                newParking(true);

                setTimeout(() => {
                    newParking(false);
                }, 800);
            }
        });

        return () => {
            socket.off('registVisitor');
            socket.off('registMuPeople');
            socket.off('registParking');
        };
    }, [socket]);

    // 페이지 이동 시 scrollTop
    useEffect(() => {
        document.oncontextmenu = () => {
            return false;
        };
        window.scrollTo(0, 0);
    }, [pathname]);

    // 움직임 없을 시 로그아웃 되는 로직
    useEffect(() => {
        if (isLoggedIn) {
            const idleInterval = setInterval(() => {
                setIdleTime((prev) => (prev += 1));
            }, 1000);

            const mouseMove = () => setIdleTime(0);
            const keyPress = () => setIdleTime(0);

            document.addEventListener('mousemove', mouseMove);
            document.addEventListener('keypress', keyPress);

            if (idleTime >= 60 * 20) {
                alert('20분간 움직임이 없어 자동 로그아웃되었습니다.');
                sessionStorage.removeItem('musinsa_reception_token');
                window.location.href = '/';
            }

            return () => {
                document.removeEventListener('mousemove', mouseMove);
                document.removeEventListener('keypress', keyPress);
                clearInterval(idleInterval);
            };
        }
    }, [idleTime, isLoggedIn]);

    return (
        <AuthProvider>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <ToastContainer />
                <Router isLoggedIn={isLoggedIn} />
            </ThemeProvider>
        </AuthProvider>
    );
}

export default App;
