import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import * as R from './RegisterTrackingNumber.style';
import { SEE_SHIPPING_DETAIL } from '@graphql/queries';
import { UPDATE_TRACKING_NUMBER } from '@graphql/mutations';
import { Query, Mutation } from '@graphql/types';
import { autoHyphen } from '@utils/common';

import Loader from '@components/share/Loader';
import StyledInput from '@components/styled/StyledInput';
import StyledButton from '@components/styled/StyledButton';
import MuiDatePicker from '@components/styled/MuiDatePicker';
import StatusChangePopup from '@components/feature/ParcelShipping/StatusChangePopup';

const RegisterTrackingNumber = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { rsId } = state;

    const [openPopup, setOpenPopup] = useState(false);
    const [trackingNum, setTrackingNum] = useState('');
    const [expectedDate, setExpectedDate] = useState<string | Date>('');

    const { data, loading } = useQuery<Pick<Query, 'seeShippingDetail'>>(
        SEE_SHIPPING_DETAIL,
        {
            variables: {
                rsId,
            },
            onError: (err) => {
                console.log(err.message);
            },
        },
    );
    const [updateTrackingNumber] = useMutation<
        Pick<Mutation, 'updateTrackingNumber'>
    >(UPDATE_TRACKING_NUMBER);

    const {
        createdAt,
        requestor,
        requestorCellphone,
        recipient,
        recipientCellphone,
        itemName,
        address,
        addressDetail,
        trackingNumber,
        pickupDate,
    } = data?.seeShippingDetail || {};

    useEffect(() => {
        if (trackingNumber) setTrackingNum(trackingNumber);
    }, [trackingNumber]);

    useEffect(() => {
        if (pickupDate) setExpectedDate(pickupDate as string);
    }, [pickupDate]);

    const openPopupHandler = () => {
        if (!trackingNum) {
            toast.warning('운송장번호를 입력해주세요.');
            return;
        } else if (!expectedDate) {
            toast.warning('집화 예정일을 선택해주세요.');
            return;
        }
        setOpenPopup(true);
    };

    const submitHandler = async () => {
        await updateTrackingNumber({
            variables: {
                rsId,
                courierCompany: 'CJ택배',
                trackingNumber: trackingNum,
                pickupDate: expectedDate,
            },
            onCompleted: () => {
                toast.success('송장번호를 등록했습니다.');
                setOpenPopup(false);
                navigate('/parcel-shipment');
            },
            onError: (err) => {
                console.log(err.message);
                setOpenPopup(false);
            },
        });
    };

    return loading ? (
        <Loader />
    ) : (
        <R.Container>
            <R.InfoWrapper>
                <R.Title>송장번호 수동 입력</R.Title>
                <R.InfoArea>
                    <R.InfoType>요청정보</R.InfoType>
                    <R.InfoListArea>
                        <R.InfoList>
                            <R.InfoTitle>요청일시</R.InfoTitle>
                            <R.InfoText>
                                {createdAt
                                    ? dayjs(createdAt).format(
                                          'YYYY-MM-DD HH:mm',
                                      )
                                    : '-'}
                            </R.InfoText>
                        </R.InfoList>
                        <R.InfoList>
                            <R.InfoTitle>요청자</R.InfoTitle>
                            <R.InfoText>
                                {requestor ? requestor : '-'}
                            </R.InfoText>
                        </R.InfoList>
                        <R.InfoList>
                            <R.InfoTitle>연락처</R.InfoTitle>
                            <R.InfoText>
                                {requestorCellphone
                                    ? autoHyphen(requestorCellphone as string)
                                    : '-'}
                            </R.InfoText>
                        </R.InfoList>
                        <R.InfoList>
                            <R.InfoTitle>받는분</R.InfoTitle>
                            <R.InfoText>
                                {recipient ? recipient : '-'}
                            </R.InfoText>
                        </R.InfoList>
                        <R.InfoList>
                            <R.InfoTitle>받는분 연락처</R.InfoTitle>
                            <R.InfoText>
                                {recipientCellphone
                                    ? autoHyphen(recipientCellphone as string)
                                    : '-'}
                            </R.InfoText>
                        </R.InfoList>
                        <R.InfoList>
                            <R.InfoTitle>상품명</R.InfoTitle>
                            <R.InfoText>{itemName ? itemName : '-'}</R.InfoText>
                        </R.InfoList>
                        <R.InfoList>
                            <R.InfoTitle>주소</R.InfoTitle>
                            <R.InfoText>
                                {address ? `${address} ${addressDetail}` : '-'}
                            </R.InfoText>
                        </R.InfoList>
                    </R.InfoListArea>
                </R.InfoArea>
                <R.InputArea>
                    <R.InfoType>처리정보</R.InfoType>
                    <R.InfoListArea>
                        <R.InfoInput>
                            <R.InfoTitleBold>택배사</R.InfoTitleBold>
                            <StyledInput
                                value="CJ택배"
                                width="calc(100% - 180px)"
                                placeholder="CJ택배"
                                disabled
                            />
                        </R.InfoInput>
                        <R.InfoInput>
                            <R.InfoTitleBold>운송장번호</R.InfoTitleBold>
                            <StyledInput
                                value={trackingNum}
                                onChange={(e) => setTrackingNum(e.target.value)}
                                width="calc(100% - 180px)"
                                placeholder="운송장번호를 입력해주세요."
                            />
                        </R.InfoInput>
                        <R.InfoInput>
                            <R.InfoTitleBold>집화예정일</R.InfoTitleBold>
                            <MuiDatePicker
                                value={
                                    expectedDate
                                        ? expectedDate
                                        : (pickupDate as string)
                                }
                                onChange={(date) => setExpectedDate(date)}
                            />
                        </R.InfoInput>
                        <R.Description>
                            집화예정일이 지나면 자동으로 발송완료 상태가 됩니다.
                        </R.Description>
                    </R.InfoListArea>
                </R.InputArea>
                <R.BtnArea>
                    <StyledButton
                        title="접수완료"
                        width="140px"
                        onClick={openPopupHandler}
                    />
                </R.BtnArea>
            </R.InfoWrapper>
            {createPortal(
                openPopup && (
                    <StatusChangePopup
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                        submitHandler={submitHandler}
                    />
                ),
                document.body,
            )}
        </R.Container>
    );
};

export default RegisterTrackingNumber;
