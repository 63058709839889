import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';
import { IoAddSharp } from 'react-icons/io5';
import { LiaDownloadSolid } from 'react-icons/lia';
import { toast } from 'react-toastify';

import * as P from './ParcelReceived.style';
import { SEE_PARCEL_POST_RECEIVED_LIST } from '@graphql/queries';
import { DOWN_PARCEL_POST_RECEIVED_LIST } from '@graphql/mutations';
import {
    Query,
    Mutation,
    ReceptionAdmin,
    ParcelPostReceived,
} from '@graphql/types';
import { userVar, selectedSite } from '@store/index';
import usePagination_v2 from '@hooks/usePagination_v2';
import { getShippingType, getParcelReceivedStatus } from '@lib/enumChange';
import { getResetDate } from '@utils/common';

import PaginationV2 from '@components/layout/PaginationV2';
import ReceivedList from '@components/feature/ParcelReceived/ReceivedList';
import Loader from '@components/share/Loader';
import MuiTableCalendar from '@components/styled/MuiTableCalendar';

const ParcelReceived = () => {
    const navigate = useNavigate();
    const workSiteUnique = useReactiveVar(selectedSite);
    const [type, setType] = useState('전체');
    const [filter, setFilter] = useState('전체');
    const [startDate, setStartDate] = useState<string | Date>(
        dayjs(new Date().setMonth(new Date().getMonth() - 1)).toISOString(),
    );
    const [endDate, setEndDate] = useState<string | Date>(
        dayjs(new Date()).toISOString(),
    );
    // 페이지네이션 관련 상태
    const [take, setTake] = useState(10);
    const [preCursor, setPreCursor] = useState(0);
    const [nextCursor, setNextCursor] = useState(0);
    const [cursorOption, setCursorOption] = useState<boolean | null>(null);

    const userInfo: ReceptionAdmin = useReactiveVar(userVar);
    const { receptionAdminPermission } = userInfo ?? {};
    const { rap_parcelPostReceive } = receptionAdminPermission ?? {};

    // 접근 권한 없는 사용자 접근 막기
    useEffect(() => {
        if (!rap_parcelPostReceive) {
            toast.warning('접근 권한이 없는 페이지 입니다.');
            navigate('/visitor');
        }
    }, [rap_parcelPostReceive, navigate]);

    const { data, loading, refetch } = useQuery<
        Pick<Query, 'seeParcelPostReceivedList'>
    >(SEE_PARCEL_POST_RECEIVED_LIST, {
        variables: {
            workSiteUnique,
            schStartDate: getResetDate(startDate),
            schEndDate: getResetDate(endDate),
            type: getShippingType(type),
            status: getParcelReceivedStatus(filter),
            take,
            preCursor,
            nextCursor,
            cursorOption,
        },
        onError: (err) => {
            console.log(err.message);
        },
    });
    const [downParcelPostReceivedList] = useMutation<
        Pick<Mutation, 'downParcelPostReceivedList'>
    >(DOWN_PARCEL_POST_RECEIVED_LIST);

    const parcelReceivedList = data?.seeParcelPostReceivedList?.pprList || [];
    const totalLength = data?.seeParcelPostReceivedList?.totalLength || 0;
    const totalCount = data?.seeParcelPostReceivedList?.totalCount || 0;
    const unreceivedCount = data?.seeParcelPostReceivedList?.unReceived || 0;
    const receivedCount = data?.seeParcelPostReceivedList?.received || 0;

    const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setType(e.target.value);
        setFilter('전체');
        resetPaginationHandler();
    };

    const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
        resetPaginationHandler();
    };

    const downloadFileHandler = async () => {
        await downParcelPostReceivedList({
            variables: {
                workSiteUnique,
                schStartDate: startDate,
                schEndDate: endDate,
                type: getShippingType(type),
                status: getParcelReceivedStatus(filter),
            },
            onCompleted: (data) => {
                window.open(data?.downParcelPostReceivedList as string);
            },
            onError: (err) => {
                console.log(err.message);
            },
        });
    };

    // 페이지네이션
    const {
        pageIndex,
        setPageIndex,
        pageGroupLength,
        prevPageHandler,
        nextPageHandler,
        prevPageGroupHandler,
        nextPageGroupHandler,
        resetPaginationHandler,
    } = usePagination_v2(
        take,
        totalLength,
        setPreCursor,
        setNextCursor,
        setCursorOption,
        parcelReceivedList,
        'pprId',
    );
    return (
        <P.Container>
            <P.Header>
                <P.TitleWrapper>
                    <P.TitleArea>
                        <P.Title>택배 및 등기 수령</P.Title>
                        <P.Select
                            name="serviceType"
                            id="serviceType"
                            value={type}
                            onChange={handleTypeChange}
                        >
                            <P.Option value="전체">전체</P.Option>
                            <P.Option value="택배">택배</P.Option>
                            <P.Option value="등기">등기</P.Option>
                        </P.Select>
                    </P.TitleArea>
                    <P.BtnWrapper>
                        <P.LinkAddList to="/parcel-recipiency/registration">
                            <IoAddSharp size={24} />
                        </P.LinkAddList>
                        <P.BtnDownload onClick={downloadFileHandler}>
                            <LiaDownloadSolid size={20} />
                        </P.BtnDownload>
                    </P.BtnWrapper>
                </P.TitleWrapper>
                <P.FilterWrapper>
                    <P.StatusWrapper>
                        <P.Label htmlFor="전체">
                            <P.Radio
                                type="radio"
                                id="전체"
                                name="parcelReceived"
                                value="전체"
                                checked={filter === '전체'}
                                onChange={handleFilter}
                            />
                            <P.FilterText>전체 {totalCount}</P.FilterText>
                        </P.Label>
                        <P.Label htmlFor="수령완료">
                            <P.Radio
                                type="radio"
                                id="수령완료"
                                name="parcelReceived"
                                value="수령완료"
                                checked={filter === '수령완료'}
                                onChange={handleFilter}
                            />
                            <P.FilterText>
                                수령완료 {receivedCount}
                            </P.FilterText>
                        </P.Label>
                        <P.Label htmlFor="미수령">
                            <P.Radio
                                type="radio"
                                id="미수령"
                                name="parcelReceived"
                                value="미수령"
                                checked={filter === '미수령'}
                                onChange={handleFilter}
                            />
                            <P.FilterText>
                                미수령 {unreceivedCount}
                            </P.FilterText>
                        </P.Label>
                    </P.StatusWrapper>
                    <P.DatePickerWrapper>
                        <MuiTableCalendar
                            value={startDate}
                            onChange={setStartDate}
                        />
                        <P.IconWave>~</P.IconWave>
                        <MuiTableCalendar
                            value={endDate}
                            onChange={setEndDate}
                        />
                    </P.DatePickerWrapper>
                </P.FilterWrapper>
            </P.Header>
            <P.TableContainer>
                <ReceivedList />
                {loading && <Loader />}
                {(parcelReceivedList as ParcelPostReceived[])
                    .slice(pageIndex, pageIndex + take)
                    .map((list: any, idx: number) => (
                        <ReceivedList
                            key={`${idx}-${list?.pp_id}`}
                            list={list}
                            refetch={refetch}
                        />
                    ))}
                {!loading && !totalLength && (
                    <P.ListEmpty>조회된 리스트가 없습니다.</P.ListEmpty>
                )}
            </P.TableContainer>
            <PaginationV2
                take={take}
                setTake={setTake}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageGroupLength={pageGroupLength}
                totalLength={totalLength}
                prevPageHandler={prevPageHandler}
                nextPageHandler={nextPageHandler}
                prevPageGroupHandler={prevPageGroupHandler}
                nextPageGroupHandler={nextPageGroupHandler}
                resetPaginationHandler={resetPaginationHandler}
            />
        </P.Container>
    );
};

export default ParcelReceived;
