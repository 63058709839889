export const getShippingType = (status: string | number) => {
    switch (status) {
        case '전체':
            return 0;
        case '택배':
            return 1;
        case '등기':
            return 2;
        case '행낭':
            return 3;
        case 0:
            return '전체';
        case 1:
            return '택배';
        case 2:
            return '등기';
        case 3:
            return '행낭';
    }
};

export const getShippingStatus = (status: string | number) => {
    switch (status) {
        case '전체':
            return 0;
        case '미발송':
            return 1;
        case '접수완료':
            return 2;
        case '발송완료':
            return 3;
        case '취소':
            return 4;
        case 0:
            return '전체';
        case 1:
            return '미발송';
        case 2:
            return '접수완료';
        case 3:
            return '발송완료';
        case 4:
            return '취소';
    }
};

export const getParcelReceivedStatus = (status: string | number) => {
    switch (status) {
        case '전체':
            return 0;
        case '미수령':
            return 1;
        case '수령완료':
            return 2;
        case 0:
            return '전체';
        case 1:
            return '미수령';
        case 2:
            return '수령완료';
    }
};

export const getCardAcceptStatus = (status: string | number) => {
    switch (status) {
        case 0:
            return '미수령';
        case 2:
            return '직접수령';
        case 3:
            return '대리수령';
        case '미수령':
            return 0;
        case '직접수령':
            return 2;
        case '대리수령':
            return 3;
    }
};

export const getTKRentalStatus = (status: string | number) => {
    switch (status) {
        case '전체':
            return 0;
        case '보유':
            return 1;
        case '승인대기':
            return 2;
        case '예약':
            return 3;
        case '대여':
            return 4;
        case '미반납':
            return 5;
        case '검수':
            return 6;
        case '점검':
            return 7;
        case 0:
            return '전체';
        case 1:
            return '보유';
        case 2:
            return '승인대기';
        case 3:
            return '예약';
        case 4:
            return '대여';
        case 5:
            return '미반납';
        case 6:
            return '검수';
        case 7:
            return '점검';
    }
};

export const getTKType = (type: string | number) => {
    switch (type) {
        case '화물용':
            return 1;
        case '기내용':
            return 2;
        case 1:
            return '화물용';
        case 2:
            return '기내용';
    }
};

export const getEmployeeCardDeployStatus = (status: string | number) => {
    switch (status) {
        case '전체':
            return 0;
        case '미배포':
            return 1;
        case '배포완료':
            return 2;
        case 0:
            return '전체';
        case 1:
            return '미배포';
        case 2:
            return '배포완료';
    }
};
