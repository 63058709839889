import { gql } from '@apollo/client';
// 리셉션 > 사용자 정보
export const SEE_RECEPTION_ADMIN = gql`
    query SeeReceptionAdmin {
        seeReceptionAdmin {
            receptionAdmin {
                ra_id
                ra_createdAt
                ra_updatedAt
                userType
                ra_accountId
                ra_teamName
                ra_employerCode
                ra_name
                ra_groupJoinDate
                ra_birthday
                ra_address
                ra_cellphone
                ra_passwordInit
                ra_lastLoginDate
                ra_lastPasswordUpdate
                ra_privAuth
                ra_privAuthFinTime
                receptionAdminPermission {
                    rap_id
                    rap_visitor
                    rap_parking
                    rap_employeementCard
                    rap_shipping
                    rap_parcelPostReceive
                    rap_travelKit
                }
            }
        }
    }
`;
// 리셉셥 > 운영 사이트
export const SEE_RECEPTION_ADMIN_SITE_LIST = gql`
    query SeeReceptionAdminStieList {
        seeReceptionAdminStieList {
            tokenExpired
            receptionAdminSiteList {
                siteName
                siteUnique
            }
        }
    }
`;
// 리셉션 > 사원 리스트
export const SEE_EMPLOYEE_INFO = gql`
    query SeeEmployeeInfo($searchTerm: String) {
        seeEmployeeInfo(searchTerm: $searchTerm) {
            user_id
            krName
            engName
            headQuarter
            sealLead
            teamTF
        }
    }
`;
// 리셉셥 > 파트별 요청 수
export const SEE_RECEPTION_COUNT = gql`
    query SeeReceptionCount($searchDate: String!, $workSiteUnique: String!) {
        seeReceptionCount(
            searchDate: $searchDate
            workSiteUnique: $workSiteUnique
        ) {
            tokenExpired
            visitorsCount
            parkingCount
            muPeopleCount
        }
    }
`;
// 리셉션 > 방문자 목록
export const SEE_VISITORS_LIST_V2 = gql`
    query SeeVisitorsList_v2(
        $searchDate: String!
        $rvVisitorCardStatus: visitorCardStatus!
        $siteUnique: String!
        $take: Int!
        $cursor: Int!
    ) {
        seeVisitorsList_v2(
            searchDate: $searchDate
            rv_visitorCardStatus: $rvVisitorCardStatus
            siteUnique: $siteUnique
            take: $take
            cursor: $cursor
        ) {
            tokenExpired
            totalLength
            totalCount
            processedCount
            unprocessedCount
            returnCount
            cancelCount
            visitorsInfo {
                rv_id
                rv_createdAt
                rv_site
                rv_siteUnique
                rv_type
                rv_team
                rv_companyName
                rv_name
                rv_cellPhone
                rv_visitPath
                rv_carRegistStatus
                rv_carLicenseNumber
                rv_hour
                rv_min
                rv_managerName
                rv_privacyPolicyAgreeDate
                rv_visitorCardStatus
                rv_visitorCardSerial
            }
        }
    }
`;
// 리셉션 > 무신사 피플 목록
export const SEE_MUPEOPLE_VISIT_CARD_LIST = gql`
    query SeeMuPeopleVisitCardList(
        $mpvcVisitCardStatus: visitorCardStatus!
        $workSiteUnique: String!
        $take: Int
        $cursor: Int
    ) {
        seeMuPeopleVisitCardList(
            mpvc_visitCardStatus: $mpvcVisitCardStatus
            workSiteUnique: $workSiteUnique
            take: $take
            cursor: $cursor
        ) {
            tokenExpired
            totalLength
            totalCount
            processedCount
            unprocessedCount
            returnCount
            cancelCount
            muPeoplevisitInfo {
                mpvc_id
                mpvc_visitCardStatus
                mpvc_createdAt
                mpvc_visitCardSerial
                mpvc_teamName
                mpvc_name
                mpvc_cellphone
                mpvc_returnDate
                mpvc_reason
                mpvc_managerTeamName
                mpvc_managerName
            }
        }
    }
`;
// 리셉션 > 주차 목록
export const SEE_VISITORS_PARKING_LIST_V2 = gql`
    query SeeVisitorsParkingList_v2(
        $searchDate: String!
        $rvCarRegistStatus: visitorParkingStatus!
        $workSiteUnique: String!
        $take: Int!
        $cursor: Int!
    ) {
        seeVisitorsParkingList_v2(
            searchDate: $searchDate
            rv_carRegistStatus: $rvCarRegistStatus
            workSiteUnique: $workSiteUnique
            take: $take
            cursor: $cursor
        ) {
            tokenExpired
            totalLength
            totalCount
            processedCount
            unprocessedCount
            cancelCount
            visitorsParkingInfo {
                rv_id
                rv_createdAt
                rv_site
                rv_siteUnique
                rv_type
                rv_team
                rv_companyName
                rv_name
                rv_cellPhone
                rv_visitPath
                rv_carRegistStatus
                rv_carLicenseNumber
                rv_hour
                rv_min
                rv_managerName
                rv_privacyPolicyAgreeDate
                rv_visitorCardStatus
                rv_visitorCardSerial
            }
        }
    }
`;
// 리셉션 > 배송 신청서 목록
export const SEE_SHIPPING_LIST = gql`
    query SeeShippingList(
        $workSiteUnique: String!
        $searchDate: String!
        $shippingType: Int!
        $status: Int!
        $take: Int!
        $preCursor: Int!
        $nextCursor: Int!
        $cursorOption: Boolean
    ) {
        seeShippingList(
            workSiteUnique: $workSiteUnique
            searchDate: $searchDate
            shippingType: $shippingType
            status: $status
            take: $take
            preCursor: $preCursor
            nextCursor: $nextCursor
            cursorOption: $cursorOption
        ) {
            totalLength
            totalCount
            unSentCount
            pickupCount
            completeCount
            cancelCount
            shippingList {
                rsId
                receptionStatus
                createdAt
                workSiteName
                requestor
                requestorCellphone
                recipient
                recipientCellphone
                address
                addressDetail
                itemName
                trackingNumber
                pickupDate
                cancelMemo
                manager
            }
        }
    }
`;
// 리셉션 > 배송 신청 > 상세 정보
export const SEE_SHIPPING_DETAIL = gql`
    query SeeShippingDetail($rsId: Int!) {
        seeShippingDetail(rsId: $rsId) {
            rsId
            receptionStatus
            createdAt
            requestor
            requestorCellphone
            recipient
            recipientCellphone
            itemName
            address
            addressDetail
            cancelMemo
            courierCompany
            trackingNumber
            pickupDate
        }
    }
`;
// 리셉션 > 택배 및 등기 수령 목록 조회
export const SEE_PARCEL_POST_RECEIVED_LIST = gql`
    query SeeParcelPostReceivedList(
        $workSiteUnique: String!
        $schStartDate: String!
        $schEndDate: String!
        $type: Int!
        $status: Int!
        $take: Int!
        $preCursor: Int!
        $nextCursor: Int!
        $cursorOption: Boolean
    ) {
        seeParcelPostReceivedList(
            workSiteUnique: $workSiteUnique
            schStartDate: $schStartDate
            schEndDate: $schEndDate
            type: $type
            status: $status
            take: $take
            preCursor: $preCursor
            nextCursor: $nextCursor
            cursorOption: $cursorOption
        ) {
            totalLength
            totalCount
            unReceived
            received
            pprList {
                pprId
                status
                type
                keepingDate
                teamName
                recipient
                sender
                memo
                receiveDate
                actualRecipient
            }
        }
    }
`;
// 리셉션 > Travel Kit 리스트
export const SEE_WORKSITE_TK_LIST = gql`
    query SeeWorksiteTKList(
        $workSiteUnique: String!
        $status: Int!
        $take: Int!
        $preCursor: Int!
        $nextCursor: Int!
        $cursorOption: Boolean
    ) {
        seeWorksiteTKList(
            workSiteUnique: $workSiteUnique
            status: $status
            take: $take
            preCursor: $preCursor
            nextCursor: $nextCursor
            cursorOption: $cursorOption
        ) {
            totalLength
            totalCount
            availableCount
            waitingApprovalCount
            reservedCount
            rentedCount
            notReturnedCount
            inspectionCount
            maintenanceCount
            travelKitList {
                tksId
                rentalStatus
                tkType
                tkName
                component
                rentalStartDate
                rentalEndDate
                userTeam
                userName
                reqText
            }
        }
    }
`;
// 리셉션 > Travel Kit 리스트 상세
export const SEE_WORKSITE_TK_DETAIL = gql`
    query SeeWorksiteTKDetail($tksId: Int!) {
        seeWorksiteTKDetail(tksId: $tksId) {
            tksId
            tkType
            tkName
            component
            size
            rentalStartDate
            rentalEndDate
            userTeam
            userName
            reqText
            latestRentedStartDate
            latestRentedEndDate
            latestRentedUserTeam
            latestRentedUserName
            latestRentedText
            rentalStatus
        }
    }
`;
// 리셉션 > 사원증 배포 목록
export const SEE_EMPLOYEE_CARD_DEPLOY_LIST = gql`
    query SeeEmployeeCardDeployList(
        $schDate: String!
        $status: Int!
        $take: Int!
        $preCursor: Int!
        $nextCursor: Int!
        $cursorOption: Boolean
    ) {
        seeEmployeeCardDeployList(
            schDate: $schDate
            status: $status
            take: $take
            preCursor: $preCursor
            nextCursor: $nextCursor
            cursorOption: $cursorOption
        ) {
            totalLength
            totalCount
            undeployed
            deployed
            employeeCardDeployList {
                ecdId
                receivedPath
                status
                arrivedDate
                receivedDate
                reqUserTeam
                reqUserName
                reqUserCellphone
                receiverTeam
                receiverName
                receiverCellphone
                managerId
                managerName
                managerTeamName
            }
        }
    }
`;
